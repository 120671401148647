import {RegDiv} from "..";
import { Link } from 'react-router-dom';

function Links(){
  const fileUrl = `${process.env.PUBLIC_URL}/ACloudPOS.rar`;
    return(
        <RegDiv >  
              
              <h2>Download Links</h2>       
             
               <ul >
               <li style={{ marginRight: "20px" }}> 
              <Link target="_blank" to="https://drive.google.com/uc?id=1IBoTNYJJoKh3ltsq5R6z9ZuRZzWmrpt3&export=download">ACloudPos Installer</Link>
                </li>
             
            <li>              
                <a href={fileUrl} download>
                ACloudPos Package 
                </a>
              </li>

              <li>
                <Link target="_blank" to="https://drive.google.com/uc?id=14-qBuhr2ZhFXuIducBpDa_dtb-rUqhtt&export=download">.Net FrameWork </Link>
              </li>
          </ul>        

            </RegDiv>
    )
}

export default Links;