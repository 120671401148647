import { Helmet } from 'react-helmet';

import {RegDiv} from "../../Components";
import Links from "../../Components/Links";
 
import ImageRotator from '../../Components/ImageRotator';
import Footer from '../../Components/Footer';
import TittleNavBar from '../../Components/TittleNavBar';
import GoogleAnalytics from '../../Components/GoogleAnalytics';

function HomePage(){

    return (
      <div>
      <GoogleAnalytics></GoogleAnalytics>
      <Helmet>
          <meta charset="UTF-8"/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <title>Revolutionize Your Restaurant with ACloud POS System</title>
          <meta name="description" content="Discover ACloud Restaurant POS System in Vancouver, the best affordable cloud-based POS software for small restaurants. Optimize operations with advanced features and seamless integration."/>
          <meta name="keywords" content="Restaurant POS System, Affordable POS System for Restaurants, Cloud-Based POS Software, Restaurant Management Software, Small Business POS System"/>
      </Helmet>
      <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
      <RegDiv width="95vw" padding="4%">
          <RegDiv flexdirection="row">
              <TittleNavBar/> 
              <a href="home/cn">中文</a>
          </RegDiv>
          
          <RegDiv flexdirection="row" margin="50px 0 0 0">
              <RegDiv width="70%" padding="4vw"> 
                  <RegDiv> 
                      <RegDiv>
                          <h1>Transform Your Restaurant Operations with ACloud Restaurant POS System in Vancouver</h1> 
                          <h3>Welcome to ACloud POS - Your Affordable Cloud-Based POS Software Solution for Restaurants!</h3>
                      </RegDiv>
                      <RegDiv flexdirection="row">
                          <RegDiv width="60%">
                              <p>ACloud POS is not just software; it's a comprehensive solution designed to revolutionize your restaurant transactions and management. Seamlessly blending state-of-the-art technology with intuitive design, our advanced POS system serves as a robust tool adaptable to the distinct requirements of various scales of restaurants — from fast-food establishments and small diners to medium-sized eateries and large-scale dining establishments.</p>
                          </RegDiv>
                          <RegDiv width="30%" padding="5%">                
                              <ImageRotator></ImageRotator>
                          </RegDiv>       
                      </RegDiv>
                      <RegDiv height="10vh"></RegDiv>
                      <RegDiv>  
                          <h1>Why Choose ACloud Restaurant POS System?</h1> 
                          <h2>Cost Savings and Value</h2>       
                          <p>Unlock significant cost savings with ACloud POS, potentially saving you thousands of dollars. Our system becomes free for you through successful referrals to other restaurant owners, or at a minimal cost, while delivering efficiency, accuracy, and overall business performance enhancements.</p>
                          <h2>Comprehensive Features</h2>       
                          <p>Discover a rich array of features, functions, and detailed reports that cater to all your business needs. From inventory management to sales analytics, ACloud POS ensures you have everything you need at your fingertips.</p>
                          <h2>Tailored to Your Needs</h2>       
                          <p>Customize ACloud POS to align seamlessly with your business requirements. Adapt the system to match your unique workflows and preferences, ensuring a personalized and efficient experience.</p>
                          <h2>Data Security and Accessibility</h2>
                          <p>Rest easy knowing that your data is secure, even in the face of unexpected computer breakdowns. With ACloud POS, your information is safeguarded, and you can access it anytime, anywhere with internet service.</p>
                          <h2>Free Licensing and Installation</h2>
                          <p>Kickstart your journey with ACloud POS with our complimentary software licenses, hassle-free installation, and a generous 90-day free usage period. Experience the power of our system without any upfront costs.</p>
                          <h2>Flexible Pricing</h2>
                          <p>Our commitment to affordability continues with a low monthly usage fee post the free period. What's more, you only pay when you actively use the system, providing flexibility and cost-effectiveness.</p>
                          <h2>Assistance Information</h2>
                          <p>Tapping the Help button on pages provides quick guidance on how to navigate and operate.</p>
                          <h2>Exceptional Tech Support</h2>
                          <p>Count on our dedicated team for free technical support whenever you need assistance. Unlike other POS software vendors, we believe in providing top-notch support without additional fees.</p>
                          <p>Ready to elevate your business with ACloud POS? Explore the future of seamless transactions and optimized operations. Join the countless businesses that have embraced efficiency, cost-effectiveness, and innovation with ACloud POS. Get started today!</p>
                      </RegDiv>
                  </RegDiv>
              </RegDiv>
              <RegDiv width="20%">
                  <RegDiv>
                      <Links/>
                  </RegDiv>
              </RegDiv>  
          </RegDiv>
      </RegDiv>
      <Footer></Footer>
  </div>
  

  ); 
    
}

export default HomePage;