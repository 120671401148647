import React, { useState, useEffect } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import { RegDiv } from '../../Components';
import { GetOpenItems, SetHeaderAuthorization, UpdateItemStatus } from '../../Components/Api';
 
function TabletDisplay(){  
    const { tabletId } = useParams();
    const { storeId } = useParams();
    const { token } = useParams();
    const navigate= useNavigate();
    const [Items, setItems] = useState([]);
    const [isVisible, setIsVisible] = useState(true);
    const Processed= 4;
    // Function to toggle visibility
    
    const fetchItems = async (storeid) => {
        try {
          const data = await GetOpenItems(storeid);
          
          //console.log("Items data:",data);
          setItems(data);
        } catch (error) {
          console.error('Error fetching items:', error);
        }
      };
    const handleItemClick = async(id)=>{
         
        await UpdateItemStatus(id, Processed);
        fetchItems(storeId);
    }

    useEffect(() => {            
        if(token)        
          SetHeaderAuthorization(token);
        else
            navigate("/");  
        
        fetchItems(storeId);   
    }, [storeId]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchItems(storeId);
        }, 10000); // 30000 milliseconds = 30 seconds

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    });

    return(
        <RegDiv mobiledisplay='fullscreen' > 
            <RegDiv flexdirection='row'>
                <RegDiv width='15vw'></RegDiv>
                <RegDiv width='40vw'>
                <button               
                    onClick={() => setIsVisible(true)}
                    style={{    
                        width:'200px',      
                        height: '50px',
                        backgroundColor:'green',
                        margin:'8px',
                        fontSize:'30px'
                    }}
                    >
                   新菜 New
                </button>
                </RegDiv>
                <RegDiv width='40vw'>
                <button               
                    onClick={() => setIsVisible(false)}
                    style={{    
                        width:'200px',      
                        height: '50px',
                        margin:'8px',
                        fontSize:'30px'
                    }}
                    >
                    已出 Done 
                </button>
                </RegDiv>
            </RegDiv> 
            {/* New items 新菜显示 */}
            <RegDiv >
              
                <ul>
                    {  isVisible && Items && Items.filter(item => item.PrinterId == tabletId && 
                                                        item.OrderStatus === 0).map((item) => (
                        <li key={item.Id}>
                            <button
                                key={item.Id}
                                onClick={() => handleItemClick(item.Id)}
                                style={{                           
                                    height: '70px',
                                    backgroundColor:'green',
                                    margin:'8px',
                                    fontSize:'50px'
                                }}
                                >
                                {`${item.Quantity} x ${item.Name} ${item.Name2}`}
                            </button>
                            {item.Notes!==null && item.Notes!=="" &&
                            <button               
                                
                                style={{
                            
                                    height: '70px',
                                    backgroundColor:'green',
                                    color:'red',
                                    margin:'8px',
                                    fontSize:'50px'
                                }}
                                >
                                {`${item.Notes}`}
                            </button>
                            }
                            {item.Modifier.length>0 &&
                            <button               
                                
                                style={{
                            
                                    height: '70px',
                                    backgroundColor:'green',
                                    color:'red',
                                    margin:'8px',
                                    fontSize:'50px'
                                }}
                                >
                                {`${item.Modifier.map((itm) => 
                                    ((item.Name.length===0?'': itm.Name) +
                                    (item.Name2.length===0?'': itm.Name2)))}`}
                            </button>
                            }
                            {item.LinkedProducts.length>0 &&
                            <button               
                                
                                style={{
                            
                                    height: '70px',
                                    backgroundColor:'green',
                                    color:'red',
                                    margin:'8px',
                                    fontSize:'50px'
                                }}
                                >
                                {`${item.LinkedProducts.map((itm) => 
                                    ((item.Name.length===0?'': itm.Name) +
                                    (item.Name2.length===0?'': itm.Name2)))}`}
                            </button>
                            }

                        {item.OrderType === 0 && item.TableName!==null && item.TableName!=="" &&
                            <button               
                                
                                style={{
                            
                                    height: '70px',
                                    backgroundColor:'gray',
                                    margin:'8px',
                                    fontSize:'50px'
                                }}
                                >
                                {`${item.TableName}`}
                            </button>
                            }
                        {item.OrderType === 1  &&
                            <button               
                                
                                style={{
                            
                                    height: '70px',
                                    backgroundColor:'gray',
                                    margin:'8px',
                                    fontSize:'50px'
                                }}
                                >  
                                {`${ 'P:' + item.OrderNumber}`}
                            </button>
                            }

                            {item.OrderType === 2  &&
                            <button               
                                
                                style={{
                            
                                    height: '70px',
                                    backgroundColor:'gray',
                                    margin:'8px',
                                    fontSize:'50px'
                                }}
                                >  
                                {`${ 'D:' + item.OrderNumber}`}
                            </button>
                            }
                        </li>
                    ))}
                </ul>
              </RegDiv>

             {/* Done items 已出菜显示*/}
            <RegDiv >
   
                <ul>
                    {  !isVisible && Items && Items.filter(item => item.PrinterId == tabletId && 
                            item.OrderStatus === Processed).map((item) => (
                        <li key={item.Id}>
                        <button
                            key={item.Id}
                            
                            style={{                           
                                height: '70px',
                                
                                margin:'8px',
                                fontSize:'50px'
                            }}
                            >
                            {`${item.Quantity} x ${item.Name} ${item.Name2}`}
                        </button>
                        {item.Notes && item.Notes!=="" &&
                        <button               
                                
                            style={{
                        
                                height: '70px',
                                
                                color:'red',
                                margin:'8px',
                                fontSize:'50px'
                            }}
                            >
                            {`${item.Notes}`}
                        </button>
                        }
                        {item.Modifier.length>0 &&
                        <button               
                                
                            style={{
                        
                                height: '70px',
                                
                                color:'red',
                                margin:'8px',
                                fontSize:'50px'
                            }}
                            >
                            {`${item.Modifier.map((itm) => 
                                ((item.Name.length===0?'': itm.Name) +
                                (item.Name2.length===0?'': itm.Name2)))}`}
                        </button>
                        }
                        {item.LinkedProducts.length>0 &&
                        <button               
                            
                            style={{
                        
                                height: '70px',
                                backgroundColor:'green',
                                color:'red',
                                margin:'8px',
                                fontSize:'50px'
                            }}
                            >
                            {`${item.LinkedProducts.map((itm) => 
                                ((item.Name.length===0?'': itm.Name) +
                                (item.Name2.length===0?'': itm.Name2)))}`}
                        </button>
                        }
                    {item.OrderType === 0 && item.TableName!==null && item.TableName!=="" &&
                    <button               
                        
                        style={{
                    
                            height: '70px',
                            backgroundColor:'gray',
                            margin:'8px',
                            fontSize:'50px'
                        }}
                        >
                        {`${item.TableName}`}
                    </button>
                    }
                    {item.OrderType === 1  &&
                    <button               
                        
                        style={{
                    
                            height: '70px',
                            backgroundColor:'gray',
                            margin:'8px',
                            fontSize:'50px'
                        }}
                        >  
                        {`${ 'P:' + item.OrderNumber}`}
                    </button>
                    }

                    {item.OrderType === 2  &&
                    <button               
                        
                        style={{
                    
                            height: '70px',
                            backgroundColor:'gray',
                            margin:'8px',
                            fontSize:'50px'
                        }}
                        >  
                        {`${ 'D:' + item.OrderNumber}`}
                    </button>
                    }
                        </li>
                    ))}
                </ul>
              </RegDiv>
            
           
      
        </RegDiv>
    )
}

export default TabletDisplay;