import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next'; 
//import i18n from '../../Components/i18n';
import { GetMenus,GetOnlineTableOrders,GetOrder,GetOrderItem, GetProduct, GetStoreLanguages, GetTableById } from "../../Components/Api";
import { RegDiv } from '../../Components';
import ShoppingCart from '../ShoppingCart';
import { SetCurrentLanguage } from '../../Components';

Modal.setAppElement('#root'); // Assuming your root element has id 'root'
function Menus(){
  
  const { storeId } = useParams(); 
  const { tableId } = useParams();
  const [menus, setMenus] = useState([]);
  const [products, setProducts] = useState([]);
  const [cur_cate, setCategory] = useState();
  const [selected_Subcate, setSelectedSubCategory] = useState();
  const [sub_cate, setSubCategory] = useState([]);
  const [productQuantities, setProductQuantities] = useState({});
  const [Order, setOrder] = useState();
  
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [Languages, setLanguages] = useState([]);
  const [lanIndex, setLanIndex] = useState(0);
  const [tableName, setTableName] = useState('');
  const [targetLang, setTargetLan] = useState();
  const { t } = useTranslation();

  const params = new URLSearchParams(window.location.search);
  const ordertype = params.get('ordertype');

  const getOpenOrder =async (table_id)=>{
    var orders =await GetOnlineTableOrders(table_id);

    if(orders && orders.length>0)
    {
      //console.log("orders:",orders);
      setOrder(orders[0]);
    }  
    else
      setOrder(null);  
  }
  const getTableName =async (table_id)=>{
    var tabl =await GetTableById(table_id);
    setTableName(tabl.Name);
    
  }

  const openPopup = () => {
    setPopupOpen(true);
  };

  const toggleLanguage = ()=>{
    if(lanIndex===0)
    {
      setLanIndex(1);
      if(Languages)
        {
          setTargetLan(Languages[0].LangText);                 
          SetCurrentLanguage(Languages[1].LanguageCode);
        }
    }
    else
    {
      setLanIndex(0);
      if(Languages)
        {
          setTargetLan(Languages[1].LangText);        
          SetCurrentLanguage(Languages[0].LanguageCode);
        }
    }
     
    
  }
 
  const closePopup = () => {
    
    setPopupOpen(false);
  };

  const clearOrder = async()=>{    
    setPopupOpen(false);
    await getOpenOrder(tableId);
  }

  const getProductQuant = (id, order)=>{
    
    if(order===null || order === undefined)
      return 0;
   
    if(order.Items===null || order.Items===undefined)
      return 0;

    var item = order.Items.find(itm=>itm.ProductId===id);
    if(item)     
    {     
      return item.Quantity;
    }  
    else
      return 0;
  }

  const createItemByProduct = async(prod_id, order)=>{
    if(order)
    {
      var tempitem = await getOrderItem(0);
      var prod = await GetProduct(prod_id);
      tempitem.ProductId = prod.Id;
      tempitem.Name=prod.Name;
      tempitem.Name2= prod.Name2;
      tempitem.LanCode = prod.LanCode;
      tempitem.LanCode2 = prod.LanCode2;
      tempitem.ActualPrice = prod.UnitPrice;
      tempitem.LinkedCategries = prod.ComboItemCategories;
      tempitem.OrderId = order.Id;
      tempitem.Type = prod.Type;
      let cur_itemid = order.Items.reduce((max, item) => {
        return item.Item_id > max ? item.Item_id : max;
    }, 0);
      tempitem.Item_id = cur_itemid +1;
      tempitem.Taxes = prod.Taxes;
      tempitem.PrinterId = prod.PrinterId; 
      if(tempitem.PrinterId === 0)
        tempitem.PrinterId = cur_cate.PrinterId;
      tempitem.Quantity=1; 

      return tempitem;
    }
    else
      return null;
  }
  const handleAddClick = async (id) => { 
    
    var temporder={...Order}, tempitem;
    if(Order===null  || Order === undefined)
    {
      temporder = await getOrder(0);
      //console.log("Created a new order:", temporder);
      temporder.StoreId= storeId;
      temporder.TableId = tableId;
      temporder.TabaleStatus = 2;
      if(ordertype)
        temporder.OrderType = parseInt(ordertype);
      if(temporder)
      {
        tempitem = await createItemByProduct(id, temporder); 
        if(tempitem)
        {
          tempitem.Quantity=1; 
          temporder.Items= [...temporder.Items, tempitem];
        }
      }
    }
    else
    {
      if(temporder.Items)
        tempitem = temporder.Items.find(item=>item.ProductId === id);
      if(tempitem===null || tempitem===undefined)
      {
        tempitem = await createItemByProduct(id, temporder); 
        
        if(tempitem)
        {
          tempitem.Quantity=1; 
          if(temporder.Items)
            temporder.Items= [...temporder.Items, tempitem];
          else
          temporder.Items = [tempitem];
        }
      }
      else
      {
        var tmp = getProductQuant(id,Order);
        tempitem.Quantity = tmp+1;
      }   
    }   
    const updatedQuantities = {};
    updatedQuantities[id]=tempitem.Quantity;
    setProductQuantities(updatedQuantities);
    temporder.Ordertime= new Date();
    ////console.log("temp order is:",temporder);
    setOrder(temporder);         
  };

  const handleMinusClick = async (id) => {
    //////console.log("product clicked id:", id);  
    var temporder={...Order}, tempitem;
    if(Order===null || Order === undefined )
    {
      return;
    }
    else
    {
      if(temporder.Items)
        tempitem = temporder.Items.find(item=>item.ProductId === id);
      if(tempitem===null || tempitem===undefined)
      {
        return;
      }
      else
      {
        var tmp = getProductQuant(id, Order) - 1;
 
        if(tmp > 0)
          tempitem.Quantity = tmp;
        else //remove item is quantity reaches 0
        {         
          var list = temporder.Items.filter(item=>item.ProductId!==id);
          temporder.Items = list;         
        }
        temporder.Ordertime= new Date();
        setOrder(temporder);
      }   
    }       
  };

  const handleCategoryClick = (cate) => {
    // Your logic when a category is clicked
    
    setCategory(cate); 
    //console.log("subcate: ", cate.Subcategories); 
    setSubCategory(cate.Subcategories); 
    
    setSelectedSubCategory();
  };

  const handleSubCategoryClick = (cate) => {
    setProducts(cate.Products);
    setSelectedSubCategory(cate);
  };

  const fetchMenus = async (storeid) => {
    try {
      const data = await GetMenus(storeid);  
        
      setMenus(data);
      if(data && data.Subcategories)
      {
        setCategory(data.Subcategories[0]);
        setSubCategory(data.Subcategories[0].Subcategories);
        setProducts(data.Subcategories[0].Products);
      }
    } catch (error) {
      console.error('Error fetching menus:', error);
    }
  };

  const fetchLanguages = async (storeid) => {
    try {
      const data = await GetStoreLanguages(storeid); 
      setLanguages(data);
      setTargetLan(data[1].LangText);
      SetCurrentLanguage(data[0].LanguageCode); 
    }
    catch (error)
    {
      console.error('Error fetching menus:', error);
    }
    
  };

  const  getOrder = async(id) =>
  {
    try {
      const data = await GetOrder(id);  
        
      setOrder(data);
      return data;
    } catch (error) {
      console.error('Error fetching menus:', error);
      return null;
    }
  }

  const getOrderItem = async(id) =>
  {
    try {
      const data = await GetOrderItem(id);  
       
      return data;
      
    } catch (error) {
      console.error('Error fetching menus:', error);
      return null;
    }
  }

  useEffect(() => {
    fetchMenus(storeId);
    fetchLanguages(storeId);
    getOpenOrder(tableId);
    getTableName(tableId);
  }, [storeId, tableId]);

  useEffect(() => {
    // Update products when cur_cate changes
    if(selected_Subcate)
    {
      setProducts(selected_Subcate.Products);
    }
    else if (cur_cate) {
      setProducts(cur_cate.Products);
    }
  }, [cur_cate, Order]);

  useEffect(() => {
    // Update product quantities when products or Order change
    const updatedQuantities = {};
    if (products) {
      products.forEach((prod) => {
        updatedQuantities[prod.Id] = getProductQuant(prod.Id, Order);
      });
      setProductQuantities(updatedQuantities);
    }  
  }, [Order, products]); // Trigger the effect whenever Order or products change  

  return (
    <div>
      <Helmet>
      <meta charset="UTF-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <title>Menus</title>
      <meta name="description" content="Welcome to ACloud Restaurant POS Menus -  Place your order by yourself, save costs, revolutionize your business and have fun."/>
      <meta name="keywords" content="ACloud POS Menus, Cloud-based POS Scan to Order, Revolutionize Business, Cost Savings "/>
      </Helmet>
      <RegDiv position="fixed" flexdirection="row" mobileflexdirection="row" backgroundcolor="Blue">
        <RegDiv width="25vw" mobilewidth="25vw" backgroundcolor="Blue" margin="20px 0 0 0" mobilemargin="20px 0 0 0">
          <button
              key={`lan-${storeId}`}
              onClick={() =>  toggleLanguage()}
              style={{
              width: '80px',
              height: '40px',                  
              }}
              > 
              {targetLang}
          </button>
        </RegDiv>
        <RegDiv width="20vw" mobilewidth="20vw" backgroundcolor="Blue">
            <h2>{t('menus')}</h2> 
        </RegDiv>
        <RegDiv width="20vw" backgroundcolor="Blue"  margin="20px 0 0 0" mobilemargin="20px 0 0 0" mobilewidth="30vw">
            <button
                key={tableId}
                onClick={() => openPopup()}
                style={{
                width: '100px',
                height: '40px',                  
                }}
                > {t('myorder')}
            </button>
        </RegDiv>
        <RegDiv width="20vw" mobilewidth="20vw" backgroundcolor="Blue">
            <h4 style={{
                padding:'7px 0px 0px 0px',
                color:'white'                
                }}>{tableName}</h4> 
        </RegDiv>
      </RegDiv>
      <RegDiv  flexdirection="row" mobileflexdirection="row" width="100vw">
        {/* display menu category */}
        <RegDiv margin="80px 0 0 0" mobilemargin="80px 0 0 0" width="25vw" mobilewidth="25vw" >
      {menus.Subcategories && menus.Subcategories.filter(cate => cate.Type !== 2).map((cate) => (
          <RegDiv>  
            <RegDiv key={`cat-${cate.Id}`} padding="2px">
                <button               
                onClick={() => handleCategoryClick(cate)}
                style={{
                width: '95%',
                height: '50px',   
                backgroundColor: cate.Id ===cur_cate.Id? 'blue': ''           
                }}
            >
                {lanIndex===0? `${cate.Name}`:`${(cate.Name2===null || cate.Name2===undefined)? cate.Name: cate.Name2}`}
                </button>
            </RegDiv>
           {sub_cate && cur_cate.Id===cate.Id && sub_cate.filter(cat=>cat.Type!==2).map((cat)=>(
            <RegDiv>
              <RegDiv key={`cat-${cat.Id}`} padding="2px">
                <button               
                onClick={() => handleSubCategoryClick(cat)}
                style={{
                width: '85%',
                height: '40px', 
                margin: '0 0 0 10px',
                backgroundColor: (selected_Subcate && cat.Id ===selected_Subcate.Id)? 'blue': 'grey'           
                }}
            >
                {lanIndex===0? `${cat.Name}`:`${(cat.Name2===null || cat.Name2===undefined)? cat.Name: cat.Name2}`}
                </button>
              </RegDiv>
            </RegDiv>
           ))}
           </RegDiv> 
        ))}
        </RegDiv>

        {/* display products of menu category */}
        <RegDiv margin="80px 0 0 0" mobilemargin="80px 0 0 0" padding="2px" width="70vw" mobilewidth="70vw" flexdirection="row" mobileflexdirection="row" 
            alignItems='flex-start' >
          {products && products.map((prod) => (
              <RegDiv key={`prod-${prod.Id}`} width="255px" margin="0 0 30px 30px" mobilemargin="0 0 30px 20px">
                  <button                   
                    //onClick={() => handleProductClick(prod)}
                    style={{
                    width: '250px',
                    height: '200px',  
                    backgroundImage: `url('${prod.ImageUrl}')`,
                    backgroundSize: 'cover', // Adjust as needed
                    backgroundPosition: 'center', // Adjust as needed                
                    }}
                  >
                  </button>
                  <RegDiv flexdirection="row" mobileflexdirection="row">
                    <RegDiv width="50%" mobilewidth="50%">
                    <p>{lanIndex===0? `${prod.Name}`:`${(prod.Name2===null || prod.Name2===undefined)? prod.Name: prod.Name2}`}
                    : ${`${prod.UnitPrice}`}</p>
                    </RegDiv>
                    <RegDiv width="40%" mobilewidth="40%" flexdirection="row" mobileflexdirection="row" 
                        align-content="center"  >
                    <button
                      key={`plus-${prod.Id}`}
                      onClick={() => handleAddClick(prod.Id)}
                      style={{ width: '30px', height: '30px', marginTop:'6px',
                      background: 'url("/add.png")', // Replace with the path to your image
                      backgroundSize: 'cover', }}
                    > </button>
                                    
                    <h4 style={{ width: '10px', height: '20px', margin:'8px'}}>
                    {productQuantities[prod.Id]}</h4>
                    <button
                      key={`minus-${prod.Id}`}
                      onClick={() => handleMinusClick(prod.Id)}
                      style={{ width: '30px', height: '30px' , marginTop:'6px',
                      background: 'url("/minus.png")', // Replace with the path to your image
                      backgroundSize: 'cover',}}
                    > </button>
                    </RegDiv>
                  </RegDiv>

              </RegDiv >
          ))}
        </RegDiv>
       </RegDiv>

       {/* Render the Popup component */}
      <Modal
        isOpen={isPopupOpen}
        onRequestClose={closePopup}
        contentLabel="Popup"
      >
        <ShoppingCart onClose={closePopup} onSubmit={clearOrder} order={Order} langIndex={lanIndex}/>
      </Modal>
    </div>
  );
};

export default Menus;