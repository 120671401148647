import { Helmet } from 'react-helmet';

import {RegDiv} from "../../../Components";
import CNLinks from '../../../Components/CN/Links';
import CNFooter from '../../../Components/CN/Footer';
import CNTittleNavBar from '../../../Components/CN/Nav';
import GoogleAnalytics from '../../../Components/GoogleAnalytics';

function CNFeatures(){
    return (
      <div>
        <GoogleAnalytics></GoogleAnalytics>
        <Helmet>
            <title>云峰餐厅云POS系统功能介绍</title>
            <meta name="description" content="探索云峰餐厅云POS系统的功能 - 桌台管理、送餐优化、取餐服务、打卡服务、双语菜单、VIP预付服务、多显示器功能、随机订单号、QR码和自助下单、移动点餐、智能等位服务、一台多单、厨房显示屏、库存控制和报告。"/>
            <meta name="keywords" content="餐厅云POS系统, 温哥华自助点餐, 扫码点餐, 移动点餐, 云峰收银厨房显示系统（KDS）, 桌台管理, 送餐优化, 取餐服务, 打卡服务, 双语菜单, VIP预付服务, 多显示器功能, 随机订单号, 适用于小型餐厅的最佳POS系统, 移动订单处理, 智能等位服务, 一台多单, 库存控制和报告的POS软件"/>
        </Helmet>
      <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
      <RegDiv padding="4%" width="95vw">
         <CNTittleNavBar/>
        <RegDiv  flexdirection="row" margin="50px 0 0 0"> 
            
            <RegDiv  width="70%" padding="4vw">  
              <h1>云峰餐厅云POS系统功能介绍</h1> 
              <p style={{ maxWidth: '100%' }}>除了提供所有餐厅都需要的基本功能外，云峰餐厅云POS系统还提供定制化功能，以满足您特定的需求。这使云峰成为大、中、小型餐厅的最佳收银系统，提供成本效益和灵活性，满足餐厅业主多样化的需求。</p>  
              <RegDiv padding="10%" aligncontent="center">
              <img src="../page4.png" alt="云峰餐厅云POS系统功能" height="350px" width="500"/> &nbsp; &nbsp; 
              </RegDiv>
              <h3>1. 桌台管理系统:</h3>
                <p style={{ maxWidth: '100%' }}> 通过轻松添加和管理桌台简化您的餐厅运营。直接在桌边简化点餐流程，提升客户服务水平和订单处理效率。</p>
              <h3>2. 送餐外卖:</h3>
                <p style={{ maxWidth: '100%' }}> 通过云峰系统的外卖送餐服务提升客户体验。让您的餐厅提供高效可靠的外卖下单，扩大您的服务范围，满足客户在家门口的美食需求。</p>
              <h3>3. 高效取餐服务:</h3>
               <p style={{ maxWidth: '100%' }}> 利用我们的取餐服务功能为顾客提供无忧的取餐体验。为忙碌的客户提供方便的解决方案，确保您的餐厅和顾客都能享受到迅速顺畅的体验。 </p>
              <h3>4. 打卡服务和工资报告:</h3>
                <p style={{ maxWidth: '100%' }}>通过我们的打卡功能为员工提供计时服务和自动工资计算。通过详细的工资报告了解您的员工考勤情况，增强透明度，简化餐厅所有者的工资管理。</p>
              <h3>5. 双语菜单促进包容性:</h3>
                <p style={{ maxWidth: '100%' }}>轻松消除语言障碍。提供两种不同语言的菜单，确保您多样化的员工之间沟通无障碍，提升整体运营效率。</p>
              <h3>6. VIP预付服务:</h3>
                <p style={{ maxWidth: '100%' }}>为您的尊贵客户提供优质体验。让他们预付款并享受独家折扣。通过锁定客户促进忠诚度，确保提前付款，稳固您的客户群。</p>
              <h3>7. 多个显示屏幕:</h3>
                <p style={{ maxWidth: '100%' }}>通过扩展显示选项增强您的销售点系统。从广告屏幕到面向客户的显示屏和厨房显示屏，我们的多显示器功能提供了多样性，以满足不同的运营需求。</p>
              <h3>8. 随机订单编号:</h3>
                <p style={{ maxWidth: '100%' }}>摆脱传统。通过生成随机订单编号为您的订单处理添加独特的风格，为餐厅的订单管理系统增添别具一格的魅力。</p>
              <h3>9. QR码扫码自助点餐:</h3>
                <p style={{ maxWidth: '100%' }}>通过为每张桌子生成QR码来改变用餐体验。让客人通过智能手机独立下单，减少服务人员成本，提高效率，提升用餐感觉和乐趣。</p>
              <h3>10. 移动点餐:</h3>
                <p style={{ maxWidth: '100%' }}>拥抱数字时代，使用我们的移动下单功能。让员工使用智能手机或平板电脑轻松下单，不再需要传统的纸笔方式，全面提升效率。</p>
              <h3>11. 智能等位服务:</h3>
                <p style={{ maxWidth: '100%' }}>通过我们的智能等位服务改变繁忙的餐厅。实现基于QR码的排队系统，让顾客在等待时有自由探索的机会。当有桌子空出时及时接收通知，提升整体用餐体验。</p>
              <h3>12. 一台多单:</h3>
                <p style={{ maxWidth: '100%' }}> 满足动态客户需求，使一个桌台可以同时有多个订单。在同一座位内管理多样化的用餐偏好，提供灵活性和高效性。 </p>
      
              <h3>13. 厨房显示屏:</h3> 
                <p style={{ maxWidth: '100%' }}> 通过将传统的打印机替换为我们的厨房显示屏，不再费时费钱购买打印纸，维护打印机，现代化您的厨房操作，让厨房远离打印纸上的化学物质。通过数字化解决方案削减成本，提高订单准确性，实现最佳效率。</p>
              <h3>14. 库存控制和报告:</h3>
                <p style={{ maxWidth: '100%' }}>轻松掌控您餐厅的库存。监控库存水平，生成全面的库存报告，并为低库存的商品简化重新采购流程。通过我们的库存控制功能优化资源管理。</p>
            </RegDiv> 
          <RegDiv  width="20%" >
                    
              <RegDiv>
                <CNLinks/>
              </RegDiv>
          </RegDiv>       
          

        </RegDiv></RegDiv>
      <CNFooter/>
      </div>
  ); 
    
}

export default CNFeatures;