import { Helmet } from 'react-helmet';

import {RegDiv} from "../../Components";
import Doctrine from "../../Components/Doctrine";
import TittleNavBar from "../../Components/TittleNavBar";

import Footer from '../../Components/Footer';
import GoogleAnalytics from '../../Components/GoogleAnalytics';

function About(){

    return (
      <div>
        <GoogleAnalytics></GoogleAnalytics>
      <Helmet>
      <meta charset="UTF-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <title>ACloud POS Solutions - Our Vision and Mission</title>
      <meta name="description" content="Discover the vision and mission of ACloud POS Solutions. We envision a future where our POS solutions empower restaurants of all sizes, providing agility to adapt to industry trends, maximize efficiency, and elevate customer satisfaction. Our mission is to revolutionize the restaurant industry with cutting-edge Point of Sale (POS) software, delivering intuitive, scalable, and innovative solutions."/>
      <meta name="keywords" content="ACloud POS, POS Solutions, Vision, Mission, Restaurant POS, Point of Sale, Efficiency, Customer Satisfaction, Innovation, Hospitality Industry, Digital Landscape, Operational Excellence"/>

      </Helmet>
      <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
      <RegDiv padding="4%" width="90vw">
       <TittleNavBar/> 

        <RegDiv  flexdirection="row" margin="50px 0 0 0">        
          
          <RegDiv padding="2%">  
            <h2>Our Vision</h2>       
            <p> We see a future where our POS solutions empower restaurants of all sizes, providing them with the agility to adapt to evolving industry trends, maximize efficiency, and elevate customer satisfaction. </p>
             
          </RegDiv>
          <RegDiv padding="2%">
            <RegDiv>
              <Doctrine/>
            </RegDiv>        
             
          </RegDiv>

         
        </RegDiv>
        <RegDiv  flexdirection="row">  
          
        </RegDiv>
      
      </RegDiv>
      <Footer/>
      </div>
  ); 
    
}

export default About;