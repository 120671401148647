import { Helmet } from 'react-helmet';
//import { useParams } from 'react-router-dom';

import {RegDiv} from "../../../Components";
import CNTittleNavBar from '../../../Components/CN/Nav';

import Footer from '../../../Components/Footer';
 
function NCPayFail(){
    
    return (
      <div>
      <Helmet>
      <meta charset="UTF-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <title>ACloud POS Solutions - Our Vision and Mission</title>
      <meta name="description" content="Subscription successful redirect page"/>
      <meta name="keywords" content="ACloud POS, Restaurant POS Solutions,  Point of Sale"/>

      </Helmet>
      <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
      <RegDiv padding="4%" width="90vw">
       <CNTittleNavBar/> 

        <RegDiv  flexdirection="row">        
          
          <RegDiv padding="2%">  
            <h2>付款失败</h2>       
            <p> 您可以尝试重新支付. </p>
             
          </RegDiv>
          <RegDiv padding="2%">
            <RegDiv>
             
            </RegDiv>        
             
          </RegDiv>

         
        </RegDiv>
        <RegDiv  flexdirection="row">  
          
        </RegDiv>
      
      </RegDiv>
      <Footer/>
      </div>
  ); 
    
}

export default NCPayFail;