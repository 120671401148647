import React from 'react';
import { Link } from 'react-router-dom';
import './navigation.css'; // Import your CSS file for styling

const Navbar = () => {
    return (
      <nav style={{ display: "flex", justifyContent: "center", fontSize: "18px" }}>
      <ul style={{ display: "flex", listStyleType: "none", margin: "0", padding: "0" }}>        
        <li className="menu-list" style={{ marginRight: "20px", position: "relative" }}>
        <Link to="/" style={{ fontWeight: "bold" }}>
            Home
          </Link>
          <ul className="sublist">           
            <li className='wide-list'>
              <Link to="/solutions" style={{ fontWeight: "bold", width:"200px" }}>
                POS Solutions
              </Link>
            </li>
          </ul>
        </li>
        <li style={{ marginRight: "20px" }}>
          <Link to="/features" style={{ fontWeight: "bold" }}>
            Features
          </Link>
        </li>
        <li className="menu-list" style={{ marginRight: "20px", position: "relative" }}>
          <Link to="/prices" style={{ fontWeight: "bold" }}>
            Pricing
          </Link>
          <ul className="sublist">           
            <li className='wide-list'>
              <Link to="/pay" style={{ fontWeight: "bold", width:"200px" }}>
                Make Payment
              </Link>
            </li>
          </ul>
        </li>
        <li className="menu-list" style={{ marginRight: "20px", position: "relative" }}>
          <Link to="/about" style={{ fontWeight: "bold" }}>
            About
          </Link>
          <ul className="sublist">
            <li>
              <Link to="/contact" style={{ fontWeight: "bold" }}>
                Contact
              </Link>
            </li>
            <li className='wide-list'>
              <Link to="/stories" style={{ fontWeight: "bold", width:"200px" }}>
                Success Stories
              </Link>
            </li>
          </ul>
        </li>
        <li style={{ marginRight: "20px" }}>
          <Link to="/signin" style={{ fontWeight: "bold" }}>
            Sign In
          </Link>
        </li>
        <li style={{ marginRight: "20px" }}>
          <Link to="/faq" style={{ fontWeight: "bold" }}>
            FAQ
          </Link>
        </li>
        
      </ul>
    </nav>
    
      );
    };


export default Navbar;
