import React, { useState } from 'react';
//import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../SignIn/SignInPage.css'; // Import your CSS file for styling
import {RegDiv} from "../../../Components";
import Footer from '../../../Components/Footer';
import CNTittleNavBar from '../../../Components/CN/Nav';
import { GetAmountCheckOutUrl } from '../../../Components/Api';
import GoogleAnalytics from '../../../Components/GoogleAnalytics';

const CNPay = () => {
  //const navigate = useNavigate();
  const [formData, setFormData] = useState({
    payamount: 0,
    
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit =async (e) => {
    e.preventDefault();
    // Redirect to stripe amount payment
    const suc_url=  "https://acloudpos.com/paysuccess/cn";
    const can_url = "https://acloudpos.com/payfail/cn";
    
    const checkout_url = await GetAmountCheckOutUrl(formData.payamount * 100,suc_url,can_url);
    window.open(checkout_url, '_blank');
    //navigate("/account");
  };

  return (
    <div>
      <GoogleAnalytics></GoogleAnalytics>
        <Helmet>
        <title>ACloud Pos System</title>
        <meta name="ACloud Pos Payment"    content=" Make payment on ACloud POS System." />
      </Helmet>
      <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
      <RegDiv padding="4%" width="95vw">
        <CNTittleNavBar/>
        <RegDiv flexdirection="row" >
          <RegDiv width="30vw"></RegDiv>
          <RegDiv padding="2%" width="40vw">
            <div className="sign-in-page">
            <div className="sign-in-container">
                <h2>付 款</h2>
                <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="payamount">输入金额</label>
                    <input
                    type="payamount"
                    id="payamount"
                    name="payamount"
                    value={formData.payamount}
                    onChange={handleChange}
                    required
                    />
                </div>

                <button type="submit">提 交</button>
                </form>
            </div>
            </div>
          </RegDiv>
          <RegDiv width="25vw"></RegDiv>
        </RegDiv>
      </RegDiv>
      <Footer></Footer>
    </div>
    
  );
};

export default CNPay;