import styled from "styled-components";

import i18n from './i18n';

export const SetCurrentLanguage =(langcode)=>{

  if(langcode.includes('en'))
    i18n.changeLanguage('en');
  else if(langcode.includes('zh'))
    i18n.changeLanguage('zh');
}


export const RegDiv = styled.div`
  width: ${props => props.width ? props.width : "100%"};
  height: ${props => props.height ? props.height : "auto"};
  display: ${props => props.display ? props.display : "flex"};
  flex-direction: ${props => props.flexdirection ? props.flexdirection : "column"};
  padding: ${props => props.padding ? props.padding : "0vw" };
  margin: ${props => props.margin ? props.margin : "0 0 0 0"};
  marginleft:${props => props.marginleft ? props.marginleft : "0px"};
  align-content: ${props => props.aligncontent ? props.aligncontent : "flex-start"};
  background-color: ${props => props.backgroundcolor ? props.backgroundcolor : "White"};
  color: ${props => props.color ? props.color : "black"}
  text-align: center;
  flex-wrap: wrap;
  position: ${props => props.position ? props.position : "static"};
  justify-content: ${props => props.justifycontent ? props.justifycontent : "flex-start"};
  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    width: ${props => props.mobilewidth ? props.mobilewidth : "100%"};
    justify-content: ${props => props.mobilejustifycontent ? props.mobilejustifycontent : "flex-start"};
    flex-direction:  ${props => props.mobileflexdirection ? props.mobileflexdirection : "column"};
    align-content:  ${props => props.mobilealigncontent ? props.mobilealigncontent : "flex-start"};
    margin: ${props => props.mobilemargin ? props.mobilemargin : "0 0 0 0"};
    display: ${props => props.mobiledisplay ? props.mobiledisplay : "flex"};
    position: ${props => props.position ? props.position : "static"};
  }
`;
