import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './Locales/en.json'; // Import English translations
import zh from './Locales/zh.json'; // Import Chinese translations

i18n
  .use(initReactI18next) // for react
  .init({
    lng: 'en', // default language
    resources: {
      en: {
        translation: en
      },
      zh: {
        translation: zh 
      }
    }
  });

export default i18n;
