import React, { useEffect } from 'react';

import { RegDiv } from '../../Components';

function Confirm ({onYes, onNo}){
    
    useEffect(() => {      
         
    });

    return(
        <RegDiv flexdirection="row"> 
            <RegDiv width="25vw"></RegDiv> 
            <RegDiv width="40vw">
            <RegDiv height="100px" >
                <h3>Are you sure to cancel your subscription?</h3>
            </RegDiv>
            <RegDiv flexdirection="row" mobileflexdirection="row" height="100px">
                <RegDiv width ="20vw" mobilewidth="20vw">
                    <button onClick={onYes}
                    style={{
                    width: '80px',
                    height: '40px',                  
                    }}
                    >   
                    Yes</button> 
                </RegDiv>
                <RegDiv width="20vw" mobilewidth="20vw">
                    <button onClick={onNo}
                    style={{
                        width: '80px',
                        height: '40px',                  
                        }}
                    >
                        No</button>  
                </RegDiv>
            
            </RegDiv>
            </RegDiv>
            <RegDiv width="25vw"></RegDiv>  
        </RegDiv>
    )
}

export default Confirm;