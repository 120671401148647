import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import { RegDiv } from '..';
 
function DateTimePicker({onOk, onCancel}){
  // State for storing date and time values
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState('12:00');
 
  // Handler for date change
  const handleDateChange = date => {
    setSelectedDate(date);
  };

  // Handler for time change
  const handleTimeChange = time => {
    setSelectedTime(time);
  };

  const handleOk = () => {
    // Pass parameters to the onOk function when it is called
    onOk(selectedDate, selectedTime);
  };

  return (
    <div>
        <RegDiv flexdirection="row">
            <RegDiv width="30vw"></RegDiv>
            <RegDiv width="30vw">
        <h2>Select Date and Time</h2>
        {/* Date picker */}
        <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="MMMM d, yyyy"
        />
        <br />
        {/* Time picker */}
        <TimePicker
            value={selectedTime}
            onChange={handleTimeChange}
            disableClock={true} // Hide clock
        />
        <br />
        {/* Display selected date and time */}
        <p>
            Selected Date: {selectedDate.toLocaleDateString()}<br />
            Selected Time: {selectedTime}
        </p>
        <br/>
        <RegDiv flexdirection="row" padding="2vw">
            <button  onClick={handleOk}
                            style={{
                            width: '80px',
                            height: '40px',                  
                        }}> Ok</button>
             <RegDiv width="6vw"></RegDiv>
            <button onClick={onCancel}
                            style={{
                            width: '80px',
                            height: '40px',                  
                        }}> Cancel</button>
        </RegDiv>
            </RegDiv>
            <RegDiv width="30vw"></RegDiv>
        </RegDiv>
    </div>
  );
};
 
export default  DateTimePicker  
