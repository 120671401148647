import { Helmet } from 'react-helmet';

import {RegDiv} from "../../Components";
import TittleNavBar from "../../Components/TittleNavBar";

import Footer from '../../Components/Footer';
import GoogleAnalytics from '../../Components/GoogleAnalytics';
import Links from '../../Components/Links';

function Stories(){

    return (
        <div>
        <GoogleAnalytics></GoogleAnalytics>
        <Helmet>
            <meta charset="UTF-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <title>ACloud POS Solutions - What Customers Say</title>
            <meta name="description" content="Discover how ACloud Restaurant Management Software solves problems and saves money for restaurant owners. Read POS success stories and learn about our effective restaurant management solutions."/>
            <meta name="keywords" content="Restaurant Management Software, POS Success Stories, Restaurant Management Solutions, ACloud POS, Restaurant POS Pricing, Cost Savings, Features, Customization, Data Security, Remote Access, Technical Support"/>
        </Helmet>
        <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
        <RegDiv padding="4%" width="95vw">
            <TittleNavBar/> 
            <RegDiv flexdirection="row" margin="50px 0 0 0">
                <RegDiv padding="2%" width="70vw">
                    <RegDiv padding="2%" width="70vw">
                        <h2>Beeryani Indian Kitchen</h2>       
                        <p>Beeryani Indian Kitchen is a cozy restaurant located in Vancouver, BC. It's the third establishment opened by Sharma. Sharma has always sought a more affordable yet reliable POS system for his restaurants. After switching to ACloud's restaurant management software, he has been extremely satisfied with its dependability, user-friendliness, and competitive pricing. He remarked, "I plan to open more restaurants and will definitely continue using your service."</p>
                    </RegDiv>
                    <RegDiv padding="2%" width="70vw">  
                        <h2>Don Asian Restaurant</h2>       
                        <p>Don Asian is a small to medium-sized restaurant with over 10 years of history. For more than 6 years, Don Asian has been using our POS software and has been very satisfied with its reliability, ease of use, and features.</p>
                        <p>The Covid-19 pandemic brought new challenges to Don Asian. Post-pandemic, the restaurant experienced dramatic fluctuations in customer flow during dining hours. On busy days, there was a shortage of staff, while on slower days, excess staff were unnecessary.</p>
                        <p>Another challenge was increasing the table turnover rate for higher profits. With 14 tables of various sizes, a low turnover rate meant that income could not cover costs.</p>
                        <p>When Don Asian’s owner, Danny, approached us for a solution, we analyzed his situation and concerns together. We proposed our scan-to-order service, which places a unique QR code on each table. Customers can scan the code with their smartphones to place orders directly. This eliminates the need for additional staff during peak hours and reduces wait times for customers, as orders are sent to the kitchen immediately.</p>
                        <p>As a result, Don Asian has saved over eight thousand dollars each month in labor costs and shortened table turnover time during peak hours. The service is quicker, customer satisfaction has increased, and customers are more likely to return. This has led to higher income for Don Asian.</p>
                        <p>Danny is extremely pleased with the results and said, "Thank you guys for helping me out. The result is outstanding and beyond my expectations. I will refer your services to all of my friends."</p>
                    </RegDiv>
                    <RegDiv width="70vw">
                        More to come.....
                    </RegDiv>
                </RegDiv>
                <RegDiv width="20vw">
                    <Links/>
                </RegDiv>
            </RegDiv>
            <RegDiv flexdirection="row">
            </RegDiv>
        </RegDiv>
        <Footer/>
    </div>
    
  ); 
    
}

export default Stories;