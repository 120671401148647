import { Helmet } from 'react-helmet';
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import {RegDiv} from "../../Components";
import TittleNavBar from "../../Components/TittleNavBar";
import Footer from '../../Components/Footer';
import { CancelSubscription, GetCheckOutUrl, SetHeaderAuthorization } from '../../Components/Api';
import StoreFunctions from '../../Components/StoreFunctions';
import Confirm from '../../Components/Confirm';
import SalesReport from '../../Components/SalesReport';
import GoogleAnalytics from '../../Components/GoogleAnalytics';


function Account(){
    const [userinfo, setUserInfo] = useState();
    const [Token, setToken] = useState();
    const navigate= useNavigate();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [confirmOpen,setConfirmOpen] = useState(false);
    const [salesOpen,setSalesOpen] = useState(false);

    const openPopup = () => {
        setPopupOpen(true);
      };
    const closePopup = () => {
    //console.log("Closing shopping cart")
    setPopupOpen(false);
    };

    const openConfirm =()=>{
      setConfirmOpen(true);
    }
    const closeConfirm = async()=>{
      setConfirmOpen(false);
    }
    const openSalesReport =()=>{
      setSalesOpen(true);
    }
    const closeSalesReport = async()=>{
      setSalesOpen(false);
    }

    const setUserAuth = async()=>{
        var usr_json = sessionStorage.getItem("userinfo");
        var usr = JSON.parse(usr_json);
        //console.log("userinfo:",usr);
        setUserInfo(usr);
        var token = sessionStorage.getItem("token");       
        //console.log("Token:",token);
        setToken(token);
        if(token)
          SetHeaderAuthorization(token);
        else
            navigate("/");
        
    }

    const onSubscribe = async()=>{
        const token=sessionStorage.getItem("token");
        //console.log("token:", token);
        const suc_url=  "https://acloudpos.com/subscribed";
        const can_url = "https://acloudpos.com/canceled";
        const checkout_url = await GetCheckOutUrl(token, suc_url, can_url);
        //console.log("Checkout url:", checkout_url);
        setPopupOpen(false);
        window.open(checkout_url, '_blank');
    }

    const cancelSubscript = async()=>{
      await CancelSubscription();
      setConfirmOpen(false);
    }

   
     
    useEffect(() => {
      setUserAuth();
    },[]);

    return (
      <div>
        <GoogleAnalytics></GoogleAnalytics>
      <Helmet>
      <meta charset="UTF-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <title>ACloud POS Solutions - Our Vision and Mission</title>
      <meta name="description" content="Acloud POS user account page, displaying account profile, subscribe and display sales data."/>
      <meta name="keywords" content="ACloud POS member account, sales report, subscribe"/>

      </Helmet>
      <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
      <RegDiv padding="4%" >
      <TittleNavBar/> 

        <RegDiv flexdirection="row">                 
          <RegDiv padding="2%" width="25vw">  
            <h2>Account Info</h2>   
            <RegDiv backgroundcolor="cornsilk" width="70%">   
              <p>Username: {userinfo ? userinfo.Username : ""}</p>
              <p>First Name: {userinfo ? userinfo.Firstname : ""}</p>
              <p>Last Name: {userinfo ? userinfo.Lastname : ""}</p>
              <p>Phone: {userinfo ? userinfo.Phone : ""}</p>
            </RegDiv> 
          </RegDiv>

          {userinfo && userinfo.StoreId > 0 && (
            <>
              <RegDiv padding="2%" width="25vw">
                <h2>Manage Subscription</h2>
                <button onClick={() => openPopup()}>Subscribe</button> <br/>  
                <button onClick={() => openConfirm()}>Cancel Subscription</button> <br/> <br/>
              </RegDiv> 

              <RegDiv padding="2%" width="35vw">  
                <h2>Reports</h2>
                <button onClick={() => openSalesReport()}>Sales Report</button> <br/>  
                {/* <button>Inventory</button> <br/> <br/>
                <button>VIP Members</button> <br/>  
                <button>Work Logs</button> */}
              </RegDiv>
            </>
          )}

          {userinfo && userinfo.StoreId === 0 && (
            <>
              <RegDiv padding="2%" width="35vw">
                <h2>Choose operations</h2>
                <li key={userinfo.Id} style={{ marginRight: "20px" }}>
                  {
                    userinfo.Role === 0 &&(
                      <Link to={`/stores/${Token}`}>Online Orders</Link>
                    )
                  }

                  {
                    userinfo.Role === 1 &&(
                      <Link to={`/stores/${Token}`}>Kitchen Tablets</Link>
                    )
                  }
                 </li>   
                                 
              </RegDiv> 
       
            </>
          )}
        </RegDiv>

      
      </RegDiv>
      <Footer/>

      <Modal
        isOpen={isPopupOpen}
        onRequestClose={closePopup}
        contentLabel="Popup"
      >
        <StoreFunctions onCancel={closePopup} onConfirm={onSubscribe}/>
      </Modal>

      <Modal
        isOpen={confirmOpen}
        onRequestClose={closeConfirm}
        contentLabel="Confirm"
      >
        <Confirm onYes={cancelSubscript} onNo={closeConfirm}/>
      </Modal>

      <Modal
        isOpen={salesOpen}
        onRequestClose={closeSalesReport}
        contentLabel="Sales"
      >
        <SalesReport onExit={closeSalesReport} storeId={userinfo?userinfo.StoreId:0}/>
      </Modal>
      </div>
  ); 
    
}

export default Account;