import { Helmet } from 'react-helmet';
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

import {RegDiv} from "../../../Components";
import CNTittleNavBar from '../../../Components/CN/Nav';
import CNFooter from '../../../Components/CN/Footer';
import { CancelSubscription, GetCheckOutUrl, SetHeaderAuthorization } from '../../../Components/Api';
import CNStoreFunctions from '../../../Components/CN/StoreFunctions';
import CNConfirm from '../../../Components/CN/Confirm';
import CNSalesReport from '../../../Components/CN/SalesReport';
import GoogleAnalytics from '../../../Components/GoogleAnalytics';


function CNAccount(){
    const [userinfo, setUserInfo] = useState();
    const navigate= useNavigate();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [confirmOpen,setConfirmOpen] = useState(false);
    const [salesOpen,setSalesOpen] = useState(false);

    const openPopup = () => {
        setPopupOpen(true);
      };
    const closePopup = () => {
    //console.log("Closing shopping cart")
    setPopupOpen(false);
    };

    const openConfirm =()=>{
      setConfirmOpen(true);
    }
    const closeConfirm = async()=>{
      setConfirmOpen(false);
    }
    const openSalesReport =()=>{
      setSalesOpen(true);
    }
    const closeSalesReport = async()=>{
      setSalesOpen(false);
    }

    const setUserAuth = async()=>{
        var usr_json = sessionStorage.getItem("userinfo");
        var usr = JSON.parse(usr_json);
        //console.log("userinfo:",usr);
        setUserInfo(usr);
        var token = sessionStorage.getItem("token");       
        
        if(token)
          SetHeaderAuthorization(token);
        else
            navigate("/home/cn");
        
    }

    const onSubscribe = async()=>{
        const token=sessionStorage.getItem("token");
        //console.log("token:", token);
        const suc_url=  "https://acloudpos.com/subscribed";
        const can_url = "https://acloudpos.com/canceled";
        const checkout_url = await GetCheckOutUrl(token, suc_url, can_url);
        //console.log("Checkout url:", checkout_url);
        setPopupOpen(false);
        window.open(checkout_url, '_blank');
    }

    const cancelSubscript = async()=>{
      await CancelSubscription();
      setConfirmOpen(false);
    }

   

    useEffect(() => {
      setUserAuth();
    },[]);

    return (
      <div>
        <GoogleAnalytics></GoogleAnalytics>
      <Helmet>
      <meta charset="UTF-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <title>云峰收银系统账户信息</title>
      <meta name="description" content="云峰餐馆收银系统账户信息, 收银系统销售报告及数据, 订阅和取消云峰餐饮收银服务"/>
      <meta name="keywords" content="云峰餐馆收银系统账户信息, 收银系统销售报告, 订阅云峰餐饮收银服务"/>

      </Helmet>
      <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
      <RegDiv padding="4%" >
       <CNTittleNavBar/> 

        <RegDiv  flexdirection="row" >                 
          <RegDiv padding="2%" width="25vw" >  
            <h2>账号信息</h2>   
            <RegDiv backgroundcolor="cornsilk" width="70%">   
            <p>用户名:  {userinfo?userinfo.Username:""}</p>
            <p>名:  {userinfo?userinfo.Firstname:""}</p>
            <p>姓:  {userinfo?userinfo.Lastname:""}</p>
            <p>电话:  {userinfo?userinfo.Phone:""}</p>
            </RegDiv> 
          </RegDiv>
          <RegDiv padding="2%" width="25vw">
            <h2>管理订阅</h2>
            <button onClick={() => openPopup()}>
                订阅</button> <br/>  
            <button  onClick={() => openConfirm()}>取消订阅</button> <br/> <br/>
          
          </RegDiv> 
          <RegDiv padding="2%" width="35vw">  
            <h2> 报表</h2>
            <button onClick={()=> openSalesReport()}>销售报表</button> <br/>  
            {/* <button>Inventory</button> <br/> <br/>
            <button>VIP Members</button> <br/>  
            <button>Work Logs</button> */}
          </RegDiv>       
        </RegDiv>
        
      
      </RegDiv>
      <CNFooter/>

      <Modal
        isOpen={isPopupOpen}
        onRequestClose={closePopup}
        contentLabel="Popup"
      >
        <CNStoreFunctions onCancel={closePopup} onConfirm={onSubscribe}/>
      </Modal>

      <Modal
        isOpen={confirmOpen}
        onRequestClose={closeConfirm}
        contentLabel="Confirm"
      >
        <CNConfirm onYes={cancelSubscript} onNo={closeConfirm}/>
      </Modal>

      <Modal
        isOpen={salesOpen}
        onRequestClose={closeSalesReport}
        contentLabel="Sales"
      >
        <CNSalesReport onExit={closeSalesReport} storeId={userinfo?userinfo.StoreId:0}/>
      </Modal>
      </div>
  ); 
    
}

export default CNAccount;