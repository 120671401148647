import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { RegDiv } from '../../Components';
import { GetStores } from "../../Components/Api";

function StoreList(){
    const [userinfo, setUserInfo] = useState();
    const [stores, setStores] = useState([]);
    const { token } = useParams();
  
    const getUserInfo = async()=>{
      var usr_json = sessionStorage.getItem("userinfo");
      var usr = JSON.parse(usr_json);
      console.log("userinfo:",usr);
      setUserInfo(usr);
       
  }

    useEffect(() => {
      // Fetch the list of stores from the API
      const fetchStores = async () => {
        try {
          const data = await GetStores();
          setStores(data);
        } catch (error) {
          console.error('Error fetching stores:', error);
        }
      };
  
      fetchStores();
      getUserInfo();
    }, []);

    return(
        <RegDiv >  
              
            <h2>Select a restaurant</h2>       
            
            <ul>
              {stores.map((store) => {         
                return (
                  <li key={store.Id} style={{ marginRight: "20px" }}>
                    {
                      userinfo.Role===0 && (
                       <Link to={`/tables/${store.Id}/${token}`}>{store.Name}</Link>
                      )
                    }
                    {
                      userinfo.Role===1 && (
                        <Link to={`/tablets/${store.Id}/${token}`}>{store.Name}</Link>
                      )
                    }
                    
                  </li>
                );
              })}
            </ul>
    
        </RegDiv>
    )
}

export default StoreList;