import React from 'react';
import Modal from 'react-modal';
import { RegDiv } from '..';

const WarningModal = ({ isOpen, message, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Warning Modal"
    >
      <RegDiv flexdirection="row">
            <RegDiv width="30vw"></RegDiv>
            <RegDiv width="30vw">
              <h2>{message}</h2>
              <p>{message}</p>
              <button onClick={onClose}>OK</button>
            </RegDiv>
            <RegDiv width="30vw"></RegDiv>
            
      
      </RegDiv>
    </Modal>
  );
};

export default WarningModal;