import { Helmet } from 'react-helmet';

import {RegDiv} from "../../../Components";

import Footer from '../../../Components/Footer';
import GoogleAnalytics from '../../../Components/GoogleAnalytics';
import Links from '../../../Components/Links';
import CNTittleNavBar from '../../../Components/CN/Nav';

function CNStories(){

    return (
        <div>
        <GoogleAnalytics></GoogleAnalytics>
        <Helmet>
            <meta charset="UTF-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <title>云峰餐饮收银系统客户故事 - 中小餐馆点餐系统解决方案</title>
            <meta name="description" content="了解云峰餐饮收银系统软件如何帮助餐厅解决问题，帮助餐馆老板省钱及创造价值，以及餐厅老板对云峰餐饮收银系统的评价。"/>
            <meta name="keywords" content="中小餐馆收银系统成功案例, 中小餐馆点餐系统解决方案, 云峰餐饮收银系统客户成功故事, 价格最低省钱收银系统"/>
        </Helmet>
        <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
        <RegDiv padding="4%" width="95vw">
            <CNTittleNavBar/>
            <RegDiv flexdirection="row" margin="50px 0 0 0">        
                <RegDiv padding="2%" width="70vw">
                    <RegDiv padding="2%" width="70vw">
                        <h2>比亚罗尼印度厨房</h2>       
                        <p>比亚罗尼印度厨房是一家位于不列颠哥伦比亚省温哥华的舒适餐厅。这是Sharma开的第三家餐厅。Sharma一直在寻找更实惠且可靠的餐饮点餐系统解决方案。自从切换到云峰中小餐馆收银系统后，他对其可靠性、用户友好性和竞争力的价格非常满意。他说：“我计划开更多的餐厅，并且肯定会继续使用你们的服务。”</p>
                    </RegDiv>
                    <RegDiv padding="2%" width="70vw">  
                        <h2>味道重庆</h2>       
                        <p>味道重庆是一家有着超过10年历史的中小型餐厅。超过6年来，味道重庆一直在使用云峰中小餐馆点餐系统解决方案，并对其可靠性、易用性和功能非常满意。</p>
                        <p>新冠疫情给味道重庆带来了新的挑战。疫情过后，餐厅在用餐时段经历了顾客流量的剧烈波动。繁忙的日子里，员工不足，而在清淡的日子里，过多的员工显得多余。</p>
                        <p>另一个挑战是如何提高每张桌子的翻台率以获得更高的利润。餐厅有14张不同大小的桌子，低翻台率意味着收入无法覆盖成本。</p>
                        <p>当味道重庆的老板Danny向我们寻求解决方案时，我们一起分析了他的情况和顾虑。我们建议使用我们的扫码点餐服务，每张桌子上放置一个独特的二维码。顾客可以使用智能手机扫描二维码直接下单。这消除了在高峰时段需要额外员工的需求，并减少了顾客等待服务员点餐的时间，因为订单会立即发送到厨房。</p>
                        <p>结果是，味道重庆每月节省了超过八千元的劳动力成本，并缩短了高峰时段的翻台时间。服务更快，顾客满意度提高，顾客更愿意回来。这导致了味道重庆收入的增加。</p>
                        <p>Danny对结果非常满意，他用重庆音说：“谢谢你们，结果超出预期。会向所有朋友推荐你们。”</p>
                    </RegDiv>
                    <RegDiv width="70vw">
                        更多故事待续.....
                    </RegDiv>
                </RegDiv>
                <RegDiv width="20vw">
                    <Links/>
                </RegDiv>
            </RegDiv>
            <RegDiv flexdirection="row"></RegDiv>
        </RegDiv>
        <Footer/>
    </div>
    
  ); 
    
}

export default CNStories;