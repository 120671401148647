import { Helmet } from 'react-helmet';

import {RegDiv} from "../../../Components";

import CNFooter from '../../../Components/CN/Footer';
import CNTittleNavBar from '../../../Components/CN/Nav';
import GoogleAnalytics from '../../../Components/GoogleAnalytics';

function CNContact(){

    return (
      <div>
        <GoogleAnalytics></GoogleAnalytics>
      <Helmet>
        <title>云峰收银联系方式</title>
        <meta name="description" content="云峰收银联系方式, 电话, 邮箱, 和社媒." />
        <meta name="keywords" content="温哥华云峰餐饮收银联系方式，温哥华收银系统咨询"/>
      </Helmet>
      <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
      <RegDiv padding="4%" width="90vw">
      <CNTittleNavBar/> 
          <RegDiv flexdirection="row">  
            <RegDiv width="10vw"></RegDiv>      
            <RegDiv width="35vw" padding="10%">
              <RegDiv>  
                <h1>联系我们</h1>
                <p>
                  如果您需要咨询或者疑问，请联系我们
                </p>  
              </RegDiv>
              <RegDiv  >
                <h1>邮箱  </h1>  
                <p>ACloudPos@gmail.com</p>
              </RegDiv>
              <RegDiv   >
                <h1>电话  </h1>  
                <p>+1 (604) 259-3395</p>
              </RegDiv>
            </RegDiv>
            <RegDiv width="25vw"></RegDiv>
          </RegDiv>
        <RegDiv  flexdirection="row">  
          
          
        </RegDiv>
      
      </RegDiv>
      <CNFooter/>
      </div>
  ); 
    
}

export default CNContact;