import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import {RegDiv} from "../../Components";
import TittleNavBar from "../../Components/TittleNavBar";

import Footer from '../../Components/Footer';

function Canceled(){
    const { authtoken } = useParams(); 
    return (
      <div>
      <Helmet>
      <meta charset="UTF-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <title>ACloud POS Solutions - Our Vision and Mission</title>
      <meta name="description" content="Subscription canceled redirect page"/>
      <meta name="keywords" content="ACloud POS, Restaurant POS Solutions,"/>

      </Helmet>
      <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
      <RegDiv padding="4%" width="90vw">
       <TittleNavBar/> 

        <RegDiv  flexdirection="row">        
          
        <RegDiv padding="2%" width="25vw">  
             
             
          </RegDiv>
          <RegDiv padding="2%" width="50">  
          <h2>Subscription Unsuccessful</h2>       
            <p> Do you want to <a href="/account"  >try again</a>? </p>

             
          </RegDiv>
          <RegDiv padding="2%" width="20vw">
            <RegDiv>
             
            </RegDiv>        
             
          </RegDiv>

         
        </RegDiv>
        <RegDiv  flexdirection="row">  
          
        </RegDiv>
      
      </RegDiv>
      <Footer/>
      </div>
  ); 
    
}

export default Canceled;