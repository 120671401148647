import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { RegDiv } from '../../Components';
import { GetTablets } from '../../Components/Api';
 
function Tablets(){
    const { storeId } = useParams();
    const { token } = useParams();
    const [KitchenTablets, setKitchenTablets] = useState([]);
    
    const fetchTablets = async (storeid) => {
        try {
          const data = await GetTablets(storeid);
          
          setKitchenTablets(data);
        } catch (error) {
          console.error('Error fetching tablets:', error);
        }
      };
  
    useEffect(() => {
      // Fetch the list of stores from the API
      
      fetchTablets(storeId);
    }, [storeId]);

    return(
        <RegDiv >  
              
            <h2>Select Tablet</h2>       
            
            <ul >
            {KitchenTablets && KitchenTablets.map((tblt) => (
                <li key={tblt.Id}>
                <Link to={`/tabletdisplay/${storeId}/${tblt.Id}/${token}`}>
                    <img src="/tablet.png" alt={`${tblt.Name} `} style={{ width: '40px', height: '40px', marginRight: '10px' }} />
                    {tblt.Name}
                </Link>
            </li>
            
                ))}
            </ul>        
        </RegDiv>
    )
}

export default Tablets;