import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'
//import logo from './logo.svg';
import './App.css';
import About from './Pages/About';
import Features from './Pages/Features';
import HomePage from './Pages/Home'
import Prices from './Pages/Prices';
import SignUp from './Pages/SignUp';
import SignIn from './Pages/SignIn';
import Contact from './Pages/Contact';
import FAQ from './Pages/FAQ';
import StoreList from './Pages/StoreList';
import TableList from './Pages/TableList';
import Menus from './Pages/Menus';
import Account from './Pages/Account';
import Subscribed from './Pages/Subscribed';
import Canceled from './Pages/Canceled';
import Pay from './Pages/Pay';
import PayFail from './Pages/PayFail';
import PaySuccess from './Pages/PaySuccess';
import CNHome from './Pages/Chinese/Home';
import CNAbout from './Pages/Chinese/About';
import CNContact from './Pages/Chinese/Contact';
import CNFAQ from './Pages/Chinese/FAQ';
import CNFeatures from './Pages/Chinese/Features';
import CNPrices from './Pages/Chinese/Prices';
import CNSignIn from './Pages/Chinese/SignIn';
import CNSignUp from './Pages/Chinese/SignUp';
import CNAccount from './Pages/Chinese/Account';
import Stories from './Pages/Stories';
import CNStories from './Pages/Chinese/Stories';
import CNPay from './Pages/Chinese/Pay';
import CNPaySuccess from './Pages/Chinese/PaySuccess';
import NCPayFail from './Pages/Chinese/PayFail';
import Tablets from './Pages/Tablets';
import TabletDisplay from './Pages/TabletDisplay';
import Order from './Pages/Order';
import Solutions from './Pages/Solutions';
import CNSolutions from './Pages/Chinese/Solutions';

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="" element={<HomePage />} />
          <Route path="home" element={<HomePage />} />
          <Route path="features" element={<Features />} />
          <Route path="prices" element={<Prices />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="signin" element={<SignIn />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="stores/:token" element={<StoreList/>}/>
          <Route path="tables/:storeId/:token" element={<TableList/>}/>
          <Route path="menus/:storeId/:tableId" element={<Menus/>}/>
          <Route path="order/:storeId/:token/:tableId" element={<Order/>}/>
          <Route path="account" element={<Account />} />
          <Route path="subscribed" element={<Subscribed />} />
          <Route path='canceled' element={<Canceled/>} />
          <Route path='stories' element={<Stories/>} />
          <Route path='pay' element={<Pay/>} />
          <Route path='paysuccess' element={<PaySuccess/>} />
          <Route path='payfail' element={<PayFail/>} />
          <Route path='tablets/:storeId/:token' element={<Tablets/>} />
          <Route path='tabletdisplay/:storeId/:tabletId/:token' element={<TabletDisplay/>} />
          <Route path='solutions' element={<Solutions/>} />

          <Route path="home/cn" element={<CNHome />} />
          <Route path="features/cn" element={<CNFeatures />} />
          <Route path="prices/cn" element={<CNPrices />} />
          <Route path="about/cn" element={<CNAbout />} />
          <Route path="contact/cn" element={<CNContact />} />
          <Route path="signup/cn" element={<CNSignUp />} />
          <Route path="signin/cn" element={<CNSignIn />} />
          <Route path="faq/cn" element={<CNFAQ />} />
          <Route path="account/cn" element={<CNAccount />} />
          <Route path='stories/cn' element={<CNStories/>} />
          <Route path='pay/cn' element={<CNPay/>} />
          <Route path='paysuccess/cn' element={<CNPaySuccess/>} />
          <Route path='payfail/cn' element={<NCPayFail/>} />
          <Route path='solutions/cn' element={<CNSolutions/>} />
        </Routes>
      </Router>
    </div>
    
  );
   
}

export default App;
