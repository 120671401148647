import { Helmet } from 'react-helmet';
//import { useParams } from 'react-router-dom';

import {RegDiv} from "../../Components";
import TittleNavBar from "../../Components/TittleNavBar";

import Footer from '../../Components/Footer';
import { useEffect } from 'react';
import {SetHeaderAuthorization, SaveStoreSubscription } from '../../Components/Api';

function Subscribed(){
    //const { authtoken } = useParams(); 
    const params = new URLSearchParams(window.location.search);

    // Get the value of the "authtoken" parameter
    const authtoken = params.get('authtoken');
    const session_id = params.get('session_id');
    const SaveSubscription = async(token, session)=>{
        SetHeaderAuthorization(authtoken);
        await SaveStoreSubscription(session_id);
    }

    useEffect( ()=>{
        //console.log("session_id:",session_id);
        //console.log("authtoken:",authtoken);
        SaveSubscription(authtoken,session_id);
        
        
    },[authtoken,session_id])

    return (
      <div>
      <Helmet>
      <meta charset="UTF-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <title>ACloud POS Solutions - Our Vision and Mission</title>
      <meta name="description" content="Subscription successful redirect page"/>
      <meta name="keywords" content="ACloud POS, Restaurant POS Solutions,  Point of Sale"/>

      </Helmet>
      <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
      <RegDiv padding="4%" width="90vw">
       <TittleNavBar/> 

        <RegDiv  flexdirection="row">        
          
          <RegDiv padding="2%">  
            <h2>Congratulations</h2>       
            <p> You have successfully subscribed. </p>
             
          </RegDiv>
          <RegDiv padding="2%">
            <RegDiv>
             
            </RegDiv>        
             
          </RegDiv>

         
        </RegDiv>
        <RegDiv  flexdirection="row">  
          
        </RegDiv>
      
      </RegDiv>
      <Footer/>
      </div>
  ); 
    
}

export default Subscribed;