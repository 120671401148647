import {React, useState } from 'react';
import Modal from 'react-modal';
import { RegDiv } from '../..';
import DateTimePicker from '../../DateTimePicker';
import { GetStoreSales } from '../../Api';
import WarningModal from '../../Warnning';
 

function CNSalesReport ({onExit, storeId}){
    const[isopenDatePicker, setOpenDatePicker] = useState(false);
    const[isStartDate, setIsStartDate]= useState(true);
    const[startDate, setStartDate]= useState();
    const[endDate, setEndDate] = useState();
    const[storeSales, setStoreSales]=useState();
    const[isWarningOpen, setWarningOpen] =useState(false);

    const CloseWarning=()=>{
        setWarningOpen(false);
    }

    const GetDateTime= (is_start, date,time)=>{       
        //console.log("Start date:", is_start);
        //console.log("Date:",date);
        //console.log("Time:",time);
        const year = date.getFullYear(); // Get the year (e.g., 2024)
        const month = date.getMonth() + 1; // Get the month (January is 0, so add 1)
        const day = date.getDate(); // Get the day of the month (1-31)

        // Construct the date string in the desired format (e.g., YYYY-MM-DD)
        const datestring = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        const datetimestring =datestring+ " " +time;
        //console.log("datetimestring", datetimestring);
        if(is_start)
        {
            setStartDate(datetimestring);
        }
        else
        {
            setEndDate(datetimestring);
        }
    }
    const closeDatePicker=()=>{
        setOpenDatePicker(false);
    }

    const PopDatePicker=(is_start)=>{
        setIsStartDate(is_start);
        setOpenDatePicker(true);
    }

    const onDatePickerOk = (date, time)=>{
        GetDateTime(isStartDate,date,time);
        setOpenDatePicker(false);
    }
    const onDatePickerCancel = ()=>{
        setOpenDatePicker(false);
    }

   const ReportSales=async (storeid, starttime, endtime)=>{
        if(starttime && endtime)
        {
            const sales = await GetStoreSales(storeid, starttime, endtime);
            //console.log("Sales:", sales);
            setStoreSales(sales);
        }
        else{
            setWarningOpen(true);
        }
   }

    return(
        <RegDiv>
            <h2>销售报告</h2>
            <RegDiv flexdirection="row" width="99vw"> 
                <RegDiv width="20vw">
                    <RegDiv >
                        <button onClick={() => PopDatePicker(true)}
                        style={{
                        width: '80px',
                        height: '40px',                  
                        }}>起始日期</button> <br/>
                        <button  onClick={() => PopDatePicker(false)}
                        style={{
                        width: '80px',
                        height: '40px',                  
                        }}>截至日期</button>
                    </RegDiv>
                </RegDiv> 
                <RegDiv width="55vw">
                    <RegDiv flexdirection="row">
                        <h4>自:  {startDate}   </h4>  &nbsp;&nbsp;
                        <h4>至: {endDate}</h4>  &nbsp;&nbsp;&nbsp;&nbsp;
                        <button onClick={()=>ReportSales(storeId, startDate,endDate)}
                        style={{
                        width: '80px',
                        height: '40px',                  
                    }}>报告</button>
                    </RegDiv>
                    <RegDiv>
                        <p>{"单     号,   销 售 金 额,  总 额（含 税）,   支 付 金 额,   支 付 方 式,  支 付 时 间"}</p>
                    {storeSales && storeSales.map((prod) => (
              <RegDiv key={`prod-${prod.Id}`} >
                <p>{prod.OrderNumber + ",         " + prod.Subtotal+ ", " + prod.Total + ", " + prod.Paid + ", " + prod.Method + ", " + prod.Timepaid}</p>
                </RegDiv>
                    ))}
                    </RegDiv>
                </RegDiv>  
                 
                <RegDiv width ="20vw"  >
                    <button onClick={onExit}
                        style={{
                        width: '80px',
                        height: '40px',                  
                    }}
                    >   
                    退出</button> 
                
                </RegDiv>              
            </RegDiv>
            <RegDiv flexdirection="row" width="99vw">
                <RegDiv width="30vw" height="10px"></RegDiv>
                <RegDiv width="30vw">
                    <Modal
                        isOpen={isopenDatePicker}
                        onRequestClose={closeDatePicker}
                        contentLabel="datetime"
                    >
                        <DateTimePicker onOk={onDatePickerOk} onCancel={onDatePickerCancel}/>
                    </Modal>
                    <Modal
                        isOpen={isWarningOpen}
                        onRequestClose={closeDatePicker}
                        contentLabel="datetime">
                        <WarningModal isOpen={isWarningOpen} message={"日期和时间不完整!"} onClose={CloseWarning}></WarningModal>
                    </Modal>
                </RegDiv>
                <RegDiv width="30vw"></RegDiv>
            </RegDiv>
           
        </RegDiv>
    )
}

export default CNSalesReport;