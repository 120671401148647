import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { GetStoreFunctions, GetTables } from "../../Components/Api";
import { RegDiv } from '../../Components';

function TableList(){
  const navigate = useNavigate();
  const { storeId } = useParams();
  const { token } = useParams();
   
  const [tables, setTables] = useState([]);
  const [features, setFeatures] = useState();

  const handleTableClick = (table) => {
    //navigate(`/menus/${storeId}/${table.Id}`);
    navigate(`/order/${storeId}/${token}/${table.Id}`);
  };

  const handleFoodcourtClick=()=>{
    //navigate(`/menus/${storeId}/0?ordertype=3`);
    navigate(`/order/${storeId}/${token}/0?ordertype=3`);
  }

  const handlePickupClick=()=>{
    //navigate(`/menus/${storeId}/0?ordertype=1`);
    navigate(`/order/${storeId}/${token}/0?ordertype=1`);
  }

  const fetchTables = async (storeid) => {
    try {
      const data = await GetTables(storeid);
     
      setTables(data);
      //const filteredTables = tables.filter((table) => table.Shape !== 3);

    } catch (error) {
      console.error('Error fetching tables:', error);
    }
  };
  const fetchStoreFunctions = async (storeid) => {
    try {      
      const data = await GetStoreFunctions(storeid);
      //console.log(data)
      setFeatures(data);
      //const filteredTables = tables.filter((table) => table.Shape !== 3);

    } catch (error) {
      console.error('Error fetching features:', error);
    }
  };

  useEffect(() => {
    // Fetch the tables for the selected store from the API
    
    fetchStoreFunctions(storeId);
    fetchTables(storeId);

  }, [storeId]);

  return (
    <div>
      <RegDiv>  
      <RegDiv flexdirection="row" width="90vw">
            <RegDiv padding="2vw" width="20vw">
            <button
              onClick={() => handleFoodcourtClick()}
              disabled={(features && features.TableService) ? true : false}
              style={{
                width: '120px',
                height: '50px',
                backgroundColor: (features && features.TableService) ? 'lightgray' : 'blue', // Change background color if disabled
                color: (features && features.TableService) ? 'gray' : 'inherit', // Change text color if disabled
                // Add other styles as needed
              }}
            >
              Foodcourt
            </button>

            </RegDiv>
            <RegDiv padding="2vw" width="20vw">
              <button        
                onClick={() => handlePickupClick()}
                disabled={(features && features.PickupService)? false: true}
                style={{
                  width: '120px',
                  height: '50px',
                  backgroundColor: (features && features.PickupService) ? 'blue' : 'lightgray'  , // Change background color if disabled
                  color: (features && features.PickupService) ? 'inherit':'gray'  // Change text color if disabled
                }}
              >
                Pickup
              </button>
          </RegDiv>
        </RegDiv>      
        <RegDiv width="90vw">
          <h2>Tables:</h2>
        </RegDiv>
        <RegDiv width="90vw" >
          {tables && tables.filter(table => table.Shape !== 3).map((table) => (
          <button
          key={table.Id}
          onClick={() => handleTableClick(table)}
          style={{
            width: '50px',
            height: '50px',
            left: `${table.Pos_x/2 + 50}px`, // Correct syntax for inline styles
            top: `${table.Pos_y/2 + 150}px`, // Correct syntax for inline styles
            // Add other styling properties as needed
            position: 'absolute', // Add this if you want to use absolute positioning
          }}
        >
          {`${table.Name}`}
        </button>
          ))}
        </RegDiv>
       
       </RegDiv>  
    </div>
  );
};

export default TableList;