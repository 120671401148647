import React, { useState, useEffect } from 'react';
import './ImageRotator.css'; // Import the CSS file for styling

const ImageRotator = () => {
  const images = [
    '../emenu_en.png',
    '../tabletdisplay_en.png',
    'page1_en.png',
    'page2_en.png',
    'page3_en.png',
    'page4_en.png',
    'page5_en.png',
    'page6_en.png',
    'page7_en.png',
    'page8_en.png',
    // Add more image URLs as needed
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    // Rotate images every 3 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [currentImageIndex, images.length]);

  return (
    <div className="image-rotator">
      <img src={images[currentImageIndex]} alt={` ${currentImageIndex + 1}`} />
    </div>
  );
};

export default ImageRotator;
