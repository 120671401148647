import React, { useState } from 'react';
import { RegDiv } from '..';

const TextInputModal = ({ onOk, onCancel }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleOk = () => {
    onOk(inputValue); // Pass the input value to the parent component
  };

  const handleCancel =()=>{
    onCancel();
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <RegDiv flexdirection="row">
            <RegDiv width="30vw"></RegDiv>
            <RegDiv width="30vw"><h2>Enter Text</h2>
                <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Your phone number"
                />
                <RegDiv flexdirection="row">
                    <RegDiv width="40%" padding="1vw">
                    <button onClick={handleOk}
                    style={{
                        width: '100px',
                        height: '40px',                  
                        }}
                        >Ok</button>
                    </RegDiv>
                    <RegDiv width="40%"  padding="1vw">
                    <button onClick={handleCancel}
                    style={{
                        width: '100px',
                        height: '40px',                  
                        }}
                        >Cancel</button>
                    </RegDiv>
                    
                    
                </RegDiv>
               
            </RegDiv>
            <RegDiv width="30vw"></RegDiv>
            
        </RegDiv>
      </div>
    </div>
  );
};

export default TextInputModal;
