import { Helmet } from 'react-helmet';

import {RegDiv} from "../../Components";
import Footer from '../../Components/Footer';
import TittleNavBar from '../../Components/TittleNavBar';
import GoogleAnalytics from '../../Components/GoogleAnalytics';

function Prices(){

    return (
      <div>
    <GoogleAnalytics></GoogleAnalytics>
    <Helmet>
        <meta charset="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <title>ACloud POS - Affordable POS System for Restaurants</title>
        <meta name="description" content="Discover ACloud POS, an affordable POS system for restaurants. Learn about our cost-effective POS software with transparent pricing and comprehensive features to streamline your business operations."/>
        <meta name="keywords" content="Affordable POS System for Restaurants, POS System Pricing, Cost-Effective POS Software, ACloud POS, Restaurant POS Pricing, POS Benefits, Cost Savings, Features, Customization, Data Security, Remote Access, Technical Support"/>
    </Helmet>
    <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
    <RegDiv padding="4%" width="90vw">
        <TittleNavBar/>
        <RegDiv flexdirection="row" margin="50px 0 0 0">
            <RegDiv padding="4%">
                <h2>Rewards for Referrals</h2>
                <p>Receive three months of free usage for every successful referral. For example, if you recommend our products and services to four friends, and they all become users, you'll receive a full year of complimentary service.</p>
            </RegDiv>
            <RegDiv padding="4%">  
                <h2>POS System Pricing</h2>       
                <p>1. The installation of ACloud POS software comes with a complimentary license.</p>
                <p>2. Enjoy free access to the ACloud POS service for the initial 90 days.</p>
                <p>3. Post the 90-day period, a monthly fee will be applicable for continued use of ACloud POS. For those who want to pay a lump sum software fee, please contact us for details.</p>
                <p>4. The standard monthly fee stands at $30 CAD (Canadian Dollars), about $22 USD, or 20.5 Euros.</p>
                <p>5. Choose from 14 additional features to tailor your service, each available at an additional cost of $5 per month.</p>
                <p>6. Modify your selected features at any time without affecting the monthly fee, as long as the total number of features remains constant.</p>
                <p>7. Any change in the total number of features will result in an adjustment to the monthly fee, which will be reflected in the subsequent month's bill.</p>
                <p>8. Cancel your service at your convenience, and charges will only apply until the end of the current month. No charges will be incurred from the following month onward.</p>
            </RegDiv>
        </RegDiv>
        <RegDiv flexdirection="row">
            <RegDiv padding="4%">  
                <h2>Cost-Effective POS Software Benefits</h2>       
                <p>1. ACloud POS ensures significant cost savings, potentially saving you thousands of dollars.</p>
                <p>2. Discover a comprehensive suite of features, functions, and reports tailored to meet all your business requirements.</p>
                <p>3. Customize the ACloud POS system to align with your specific needs and preferences.</p>
                <p>4. Safeguard your data even in the event of a computer breakdown, ensuring data integrity and continuity.</p>
                <p>5. Access your data securely and conveniently from anywhere with internet service, providing flexibility and real-time insights.</p>
                <p>6. Enjoy complimentary software licenses, installation, and a 90-day free usage period.</p>
                <p>7. Experience a low monthly usage fee post the free period, with the added benefit of not paying if the system goes unused.</p>
                <p>8. Benefit from free technical support, in stark contrast to other POS software vendors who often charge exorbitant amounts for both their software and ongoing support fees.</p>
            </RegDiv> 
        </RegDiv>
    </RegDiv>
    <Footer></Footer>
</div>

  ); 
    
}

export default Prices;