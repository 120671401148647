import axios from "axios"

const API_BASE_URL="https://xpos1.azurewebsites.net/api/";

export const GetStores= async ()=>{
    var resp = await axios.get(API_BASE_URL+"store/getall_by_type/0");
    if(resp.status === 200)
    {
        return resp.data;
    }
    else
        return null;
}

export const GetTables= async (storeid)=>{
    var resp = await axios.get(API_BASE_URL+"tables/store/"+storeid);
    if(resp.status === 200)
    {
        return resp.data;
    }
    else
        return null;
}

export const GetTablets= async (storeid)=>{
    var resp = await axios.get(API_BASE_URL+"printer/tablets/store/"+storeid);
    if(resp.status === 200)
    {
        return resp.data;
    }
    else
        return null;
}

export const GetOpenItems= async (storeid)=>{
    var resp = await axios.get(API_BASE_URL+"orders/items/open/"+storeid);
    if(resp.status === 200)
    {
        return resp.data;
    }
    else
        return null;
}

export const UpdateItemStatus = async (id, status) => {
    try {
        const resp = await axios.put(`${API_BASE_URL}orders/item/status/update/${id}/${status}`);
        if (resp.status === 200) {
            return resp.data;
        } else {
            console.error(`Unexpected status code: ${resp.status}`);
            return null;
        }
    } catch (error) {
        console.error('Error updating item status:', error);
        return null;
    }
}


export const GetMenus= async (storeid)=>{
    var resp = await axios.get(API_BASE_URL+"catalog/"+storeid+ "/rootcategory");
    if(resp.status === 200)
    {
        return resp.data;
    }
    else
        return null;
}

export const GetOrder= async (id)=>{
    var resp = await axios.get(API_BASE_URL+"orders/get/"+id);
    if(resp.status === 200)
    {
        return resp.data;
    }
    else
        return null;
}

export const GetOrderItem= async (id)=>{
    var resp = await axios.get(API_BASE_URL+"orders/item/get/"+id);
    if(resp.status === 200)
    {
        return resp.data;
    }
    else
        return null;
}

export const GetProduct= async (id)=>{
    var resp = await axios.get(API_BASE_URL+"catalog/product/"+id);
    if(resp.status === 200)
    {
        return resp.data;
    }
    else
        return null;
}

export const AddOrder= async (order)=>{
    var resp = await axios.post(API_BASE_URL+"orders/online/add", order);
    if(resp.status === 200)
    {
        return resp.data;
    }
    else
        return null;
}

export const SetOrderOnline= async (id)=>{
    var resp = await axios.put(API_BASE_URL+"orders/online/set/"+id);
    if(resp.status === 200)
    {
        return resp.data;
    }
    else
        return null;
}

export const AddOrderItems= async (order)=>{
    var resp = await axios.post(API_BASE_URL+"orders/online/item/add", order);
    if(resp.status === 200)
    {
        return resp.data;
    }
    else
        return null;
}

export const GetOnlineTableOrders= async (id)=>{
    var resp = await axios.get(API_BASE_URL+"orders/online/table/"+id);
    if(resp.status === 200)
    {
        return resp.data;
    }
    else
        return null;
}

export const GetTableById= async (id)=>{
    var resp = await axios.get(API_BASE_URL+"tables/table/"+id);
    if(resp.status === 200)
    {
        return resp.data;
    }
    else
        return null;
}

export const Login= async (username, pwd)=>{
    var resp = await axios.post(API_BASE_URL+"users/session/login",{
            Username:username,
            Password:pwd,
        });
    if(resp.status === 200)
    {
        var token =  resp.data.Session.AuthToken;
        var userinfo = JSON.stringify(resp.data.User);
        sessionStorage.setItem("token",token);
        sessionStorage.setItem("userinfo", userinfo);
        return resp.data;
    }   
    else 
        return null;
}

export const SetHeaderAuthorization =  (token) => {
    
    if (token === null || token === "") {
      window.open("/");
    } else {
      axios.defaults.headers.common["Authorization"] = "Basic " + token;
    }
  };

  // pay amt in dollar, in the name of what. name can be any string describing the payment info.
// return Stripe payment portal url
export const GetCheckOutUrl = async (userauthtoken, suc_url, can_url) => {
    const resp = await axios.post(API_BASE_URL + "store/checkout", {      
      Success_Url: suc_url + "?authtoken=" + userauthtoken,
      Cancel_Url:  can_url + "?authtoken=" + userauthtoken,
    });
    if (resp.status === 200) {
      return resp.data;
    } 
    else 
        return null;
  };

  export const GetAmountCheckOutUrl = async (amt, suc_url, can_url) => {
    const resp = await axios.post(API_BASE_URL + "store/amount/checkout", {        
      Success_Url: suc_url ,
      Cancel_Url:  can_url ,
      Amount: amt,     
    });   
    if (resp.status === 200) {
      return resp.data;
    } 
    else 
        return null;
  };

  export const GetStoreFunctions= async (id)=>{
    var resp = await axios.get(API_BASE_URL+"store/features/"+id);
    if(resp.status === 200)
    {
        return resp.data;
    }
    else
        return null;
}

export const SaveStoreSubscription= async (session_id)=>{
    var resp = await axios.put(API_BASE_URL+"store/subscription/"+session_id);
    if(resp.status === 200)
    {
        return resp.data;
    }
    else
        return null;
}

export const GetStoreLanguages= async (storeid)=>{
    var resp = await axios.get(API_BASE_URL+"utilities/languages/stores/"+storeid);
    if(resp.status === 200)
    {
        return resp.data;
    }
    else
        return null;
}

export const CancelSubscription= async ()=>{
    var resp = await axios.put(API_BASE_URL+"store/subscription/cancel");
    if(resp.status === 200)
    {
        return resp.data;
    }
    else
        return null;
}

export const GetStoreSales = async (storeid, starttime, endtime) => {
    const resp = await axios.post(API_BASE_URL + "pay/store", { 
        StoreId: storeid,     
        StartTime: starttime,
        EndTime:  endtime,
    });
    if (resp.status === 200) {
      return resp.data;
    } 
    else 
        return null;
  };