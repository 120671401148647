import {RegDiv} from "../../../Components";
import CNTittle from "../Tittle";
import CNNavbar from "../Navigation";

function CNTittleNavBar(){

    return (
       <div>
        <RegDiv flexdirection="row" >
        <RegDiv width="450px" >
          <CNTittle/> 
         
        </RegDiv>
        <RegDiv  >
          <RegDiv  ></RegDiv>
          <CNNavbar/>
        </RegDiv>
      </RegDiv>
      </div>
  ); 
    
}

export default CNTittleNavBar;