// SignInPage.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './SignInPage.css'; // Import your CSS file for styling
import {RegDiv} from "../../../Components";
import CNFooter from '../../../Components/CN/Footer';
import CNTittleNavBar from '../../../Components/CN/Nav';
import { Login } from '../../../Components/Api';
import GoogleAnalytics from '../../../Components/GoogleAnalytics';

const CNSignIn = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit =async (e) => {
    e.preventDefault();
    // Add your sign-in/authentication logic here
    await Login(formData.username, formData.password);
    navigate("/account/cn");
  };

  return (
    <div>
      <GoogleAnalytics></GoogleAnalytics>
        <Helmet>
        <title>云峰收银系统账户登录</title>
        <meta name="description"    content="云峰收银系统账户登录页" />
        <meta name="keywords"    content="云峰收银系统账户登录" />
      </Helmet>
      <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
      <RegDiv padding="4%" width="95vw">
        <CNTittleNavBar/>
        <RegDiv flexdirection="row">
        <RegDiv  width="30vw"/>
          <RegDiv padding="2%" width="40vw">
            <div className="sign-in-page">
            <div className="sign-in-container">
                <h2>登        录</h2>
                <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="username">用户名</label>
                    <input
                    type="username"
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="password">密码</label>
                    <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    />
                </div>

                <button type="submit">登   录</button>
                </form>
            </div>
            </div>
          </RegDiv>
          <RegDiv width="25vw"></RegDiv>
        </RegDiv>
      </RegDiv>
      <CNFooter/>
    </div>
    
  );
};

export default CNSignIn;
