import {RegDiv} from "../../Components";
import Tittle from "../../Components/Tittle";
import Navbar from "../../Navigation";

function TittleNavBar(){

    return (
       <div>
        <RegDiv flexdirection="row" >
        <RegDiv width="450px"  >
          <Tittle/>
        </RegDiv>
        <RegDiv  >
          <RegDiv  ></RegDiv>
          <Navbar/>
        </RegDiv>
      </RegDiv>
      </div>
  ); 
    
}

export default TittleNavBar;