import { Helmet } from 'react-helmet';

import {RegDiv} from "../../Components";
import Links from "../../Components/Links";
import Footer from '../../Components/Footer';
import TittleNavBar from '../../Components/TittleNavBar';
import GoogleAnalytics from '../../Components/GoogleAnalytics';

function Features(){
    return (
      <div>
        <GoogleAnalytics></GoogleAnalytics>
        <Helmet>
        <title>Comprehensive Features of ACloud Cloud-Based POS Software</title>
        <meta name="description" content="Explore ACloud's cloud-based POS software features including table management, delivery optimization, inventory control, and more. Discover the benefits of a flexible, affordable POS system tailored for small restaurants."/>
        <meta name="keywords" content="Cloud-Based POS Software, POS System Features, Cloud POS Benefits, Table Management, Delivery Optimization, Inventory Control, Affordable POS for Restaurants, Small Business POS System, Restaurant Management Software"/>
      </Helmet>
      <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
      <RegDiv padding="4%" width="95vw">
         <TittleNavBar/>
        <RegDiv  flexdirection="row" > 
            
            <RegDiv  width="70%" padding="4vw">  
              <h1>Features of ACloud POS System</h1> 
              <p style={{ maxWidth: '100%' }}>In addition to offering essential functionalities required by all restaurants, ACloud offers customizable features tailored to meet your specific requirements. This makes ACloud the optimal POS system for small restaurants, offering cost-effectiveness and flexibility that cater to the diverse needs of restaurant owners. </p>  
              <RegDiv padding="10%" aligncontent="center">
              <img src="page4_en.png" alt="ACloud POS login page" height="350px" width="500"/> &nbsp; &nbsp; 
              </RegDiv>
              <h3>1. Table Management Service:</h3>
                <p style={{ maxWidth: '100%' }}> Simplify your restaurant operations by effortlessly adding and managing tables. Streamline the ordering process directly at the table for enhanced customer service and efficient order placement.</p>
              <h3>2. Delivery Optimization:</h3>
                <p style={{ maxWidth: '100%' }}> Elevate your customer experience with our seamless delivery service. Empower your restaurant to offer efficient and reliable delivery options, expanding your reach and satisfying your customers' cravings at their doorstep.</p>
              <h3>3. Efficient Pickup Services:</h3>
               <p style={{ maxWidth: '100%' }}> Enable hassle-free order pickups with our Pickup Service feature. Provide a convenient solution for customers on the go, ensuring a swift and frictionless experience for both your restaurant and your patrons. </p>
              <h3>4. Clock Services and Wage Reports:</h3>
                <p style={{ maxWidth: '100%' }}>Empower your staff with our clock-in/clock-out functionality. Gain insights into your workforce's attendance with detailed wage reports, enhancing transparency and simplifying payroll management for restaurant owners.</p>
              <h3>5. Bilingual Menus for Inclusivity:</h3>
                <p style={{ maxWidth: '100%' }}>Overcome language barriers with ease. Create menus in two different languages, ensuring seamless communication and understanding among your diverse staff, enhancing overall operational efficiency.</p>
              <h3>6. VIP Prepaid Services:</h3>
                <p style={{ maxWidth: '100%' }}>Offer a premium experience for your valued clients. Allow them to pre-deposit funds and enjoy exclusive discounts. Secure advance payments and cultivate loyalty by locking in your clientele.</p>
              <h3>7. Multi-Monitor Capabilities:</h3>
                <p style={{ maxWidth: '100%' }}>Enhance your point-of-sale system with extended display options. From advertising screens to client-facing displays and kitchen screens, our Multi-Monitor feature offers versatility to cater to diverse operational needs.</p>
              <h3>8. Randomized Order Numbers:</h3>
                <p style={{ maxWidth: '100%' }}>Break away from the conventional. Introduce a unique touch to your order processing by generating random order numbers, adding a distinctive flair to your restaurant's order management system.</p>
              <h3>9. QR Code & Self-Ordering:</h3>
                <p style={{ maxWidth: '100%' }}>Revolutionize the dining experience by generating QR codes for each table. Empower diners to place orders independently through their smartphones, reducing labor costs and increasing efficiency.</p>
              <h3>10. Mobile Order Processing:</h3>
                <p style={{ maxWidth: '100%' }}>Embrace the digital era with our Mobile Order feature. Allow staff to seamlessly place orders using smartphones or tablets, eliminating the need for traditional pen-and-paper methods and boosting overall efficiency.</p>
              <h3>11. Smart Wait Service:</h3>
                <p style={{ maxWidth: '100%' }}>Transform busy restaurants with our Smart Wait Service. Implement a QR code-based queuing system, offering customers the freedom to explore while waiting. Receive timely notifications when tables become available, enhancing the overall dining experience.</p>
              <h3>12. Concurrent Table Orders:</h3>
                <p style={{ maxWidth: '100%' }}> Cater to dynamic customer needs by enabling tables to have multiple concurrent orders. Provide flexibility and efficiency in managing diverse dining preferences within the same seating. </p>
      
              <h3>13. Kitchen Display Screen:</h3> 
                <p style={{ maxWidth: '100%' }}> Modernize your kitchen operations by replacing traditional printers with our Kitchen Display Screen. Cut costs and improve order accuracy with a digital solution designed for optimal efficiency.</p>
              <h3>14. Inventory Control and Reporting:</h3>
                <p style={{ maxWidth: '100%' }}>Take control of your restaurant's inventory effortlessly. Monitor stock levels, generate comprehensive inventory reports, and streamline the repurchasing process for items running low. Optimize resource management with our Inventory Control feature.</p>
            </RegDiv> 
          <RegDiv  width="20%" >
                    
              <RegDiv>
                <Links/>
              </RegDiv>
          </RegDiv>       
          

        </RegDiv></RegDiv>
      <Footer></Footer>
      </div>
  ); 
    
}

export default Features;