import { Helmet } from 'react-helmet';

import {RegDiv} from "../../Components";

import Footer from '../../Components/Footer';
import TittleNavBar from '../../Components/TittleNavBar';
import GoogleAnalytics from '../../Components/GoogleAnalytics';

function Contact(){

    return (
      <div>
        <GoogleAnalytics></GoogleAnalytics>
      <Helmet>
        <title>ACloud POS Contacts</title>
        <meta name="ACloud POS Contacts" content=" ACloud POS Contacts, phones, email, and media." />
      </Helmet>
      <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
      <RegDiv padding="4%" width="90vw">
      <TittleNavBar/> 
          <RegDiv flexdirection="row">  
            <RegDiv width="10vw"></RegDiv>      
            <RegDiv width="35vw" padding="10%">
              <RegDiv>  
                <h1>Contact Us</h1>
                <p>
                  If you have any questions or inquiries, please feel free to reach out to us.
                </p>  
              </RegDiv>
              <RegDiv  >
                <h1>Email  </h1>  
                <p>ACloudPos@gmail.com</p>
              </RegDiv>
              <RegDiv   >
                <h1>Phone  </h1>  
                <p>+1 (604) 259-3395</p>
              </RegDiv>
            </RegDiv>
            <RegDiv width="25vw"></RegDiv>
          </RegDiv>
        <RegDiv  flexdirection="row">  
          
          
        </RegDiv>
      
      </RegDiv>
      <Footer></Footer>
      </div>
  ); 
    
}

export default Contact;