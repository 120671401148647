import { Helmet } from 'react-helmet';
import {RegDiv} from "../../Components";
import Links from "../../Components/Links"
import Footer from "../../Components/Footer";
import TittleNavBar from '../../Components/TittleNavBar';
import GoogleAnalytics from '../../Components/GoogleAnalytics';

function FAQ(){

    return (
      <div>
        <GoogleAnalytics></GoogleAnalytics>
        <Helmet>
        <title>ACloud POS FAQ</title>
        <meta charset="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <meta name="description" content="Explore frequently asked questions about ACloud POS. Learn how to download, install, sign up, configure settings, and more."/>
        <meta name="keywords" content="ACloud POS, Point of Sale system for Restaurants, POS system, installation, sign up, configuration, subscription, touchscreen pos computer, startup"/>
        <meta name="author" content="ACloud Software Inc."></meta>
      </Helmet>
      <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
      <RegDiv padding="4%" width="90vw">
        <TittleNavBar/>
        
        <RegDiv flexdirection="row"  >        
            
          <RegDiv padding="5%" width="70%">      
            <h1 >Questions and Answers</h1>  
            <h2>Installation and Usage</h2>
            <p>1. How do I utilize the ACloud POS system in general? <br></br>
             Steps: <br></br>
             1.1 Click one of the links on the right side to download ACloudPOS installer or rar file, and then run it or decompress it to where you want. <br></br>
             1.2 Find XPOS1-Shortcut， pin it to taskbar. <br></br>
             1.3 Launch and Sign up, then Configure your system settings. <br></br>
             1.4 Commence using the system.  
             </p>
            <p> 2. How to sign up? <br></br> 
                You need to install ACloud POS on your windows computer first, and then run it to sign up.</p>
            <p> 2.1 If I bought a business and the computer already has ACloudPOS, do I still need to sign up? <br></br> 
                Yes, you still need to sign up. Update software if there is a warning message about version mismatch.</p>    
            <p> 3. How can I download the ACloud POS software? <br></br>
            Simply click on one of the download links provided on the right. 
            Your browser might display a warning regarding potential risks to your computer. Disregard this warning and proceed with the download. Rest assured, we guarantee the safety of our POS software.
  
            </p> 
             
             <p> 4. What OS can ACloud POS software run on? <br></br>
                ACloud POS can run on Windows 7/8/10/11. </p> 
            <p> 5. Where can I purchase all-in-one touchscreen POS computers? <br></br>
              Feel free to reach out to us. We offer high-quality all-in-one touchscreen computers at competitive prices.
            </p> 
           
              <p> 6. How do I configure ACloud POS to start automatically when my computer boots up after installation? <br></br>
              You can achieve this by navigating to the installation directory of ACloud POS, copy XPOS1-Shortcut and paste it in the Windows startup folder.
                </p>     
                <p> 7. How to update my ACloud POS software? <br></br>
                To update your ACloud POS software, simply click one of the links on the right side, to download ACloudPOS installer or rar file, and then run installer or decompress rar file to overwite the old copy.  </p>     
                <p> 8. What steps should I take to uninstall ACloud POS software from my computer? <br></br>
                 Simply delete folder ACloudPOS or remove ACloudPOS from installed programs, depending on how you installed.
               </p> 
               <h2>Subscribe</h2>    
                <p>
                9. How to subscribe? <br />
                <span>                 
                  <a href="/signin" >
                  Sign in here
                  </a>
                  &nbsp; and click subscribe, then follow instructions.
                </span>
                </p>
                <p>10. Even if I subscribe now, while my free trial is still active, will I forfeit the remaining free days? <br/>
                No, you won't. Your free trial will continue uninterrupted, and your monthly subscription charge will commence only after the conclusion of your free trial period.
                </p>
            <p>
                  11. How to cancel my subscription? <br />
                  <span>                   
                    <a href="/signin" >
                    Sign in here 
                    </a>
                    &nbsp; and click cancel subscription.
                  </span>
              </p>
                <p>
                  12. What is the process for pausing my subscription if my restaurant temporarily closes? <br />
                  If you need to halt your subscription to avoid monthly fees, you can cancel your current subscription and then resubscribe when you're ready to resume using the service.
                </p>
                
          </RegDiv>
          <RegDiv  width="20%" >
                    
                    <RegDiv>
                      <Links/>
                    </RegDiv>
          </RegDiv>  
        </RegDiv>
        <RegDiv  flexdirection="row">  
         
        </RegDiv>
      
      </RegDiv>
      <Footer></Footer>
      </div>
  ); 
    
}

export default FAQ;