import { RegDiv } from "../..";

function CNDoctrine(){

    return(
        <RegDiv>
              <h2 >我们的使命</h2>    
              <p> &nbsp; &nbsp;  &nbsp; &nbsp; 
              云峰餐饮云收银的使命是通过提供超越寻常的尖端科技，
              云平台收银软件，赋予餐厅新的能力。我们致力于通过提供直观、
              可扩展和创新的解决方案，彻底改变餐厅运营方式。通过将技术卓越与
              对餐饮行业的深刻理解相结合，我们努力提高效率，简化操作，并提升餐厅
              业主和他们尊贵顾客的整体用餐体验。我们的使命是成为餐馆生意成功的催化剂，为餐厅
              提供在不断发展的数字环境中蓬勃发展所需的工具。
              展望未来，我们的软件将成为餐厅不可或缺的伙伴，使他们不仅能够满足
              客户的期望，而且能够在达到前所未有的运营卓越水平的同时超越客户的期望。
                </p>
            </RegDiv>
    )
}

export default CNDoctrine;