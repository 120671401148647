import {RegDiv} from "../../Components";

function Doctrine(){

    return(
        <RegDiv>
              <h2 >Our Mission</h2>    
              <p> &nbsp; &nbsp;  &nbsp; &nbsp; 
                At ACloud POS Solutions, our mission is to empower restaurants with cutting-edge 
                Point of Sale (POS) software that transcends the ordinary. We are committed to 
                revolutionizing the way restaurants operate by delivering intuitive, scalable, 
                and innovative solutions. By combining technological excellence with a deep 
                understanding of the hospitality industry, we strive to enhance efficiency, 
                streamline operations, and elevate the overall dining experience for both restaurant 
                owners and their valued patrons. Our mission is to be the catalyst for success, 
                providing restaurants with the tools they need to thrive in an ever-evolving digital 
                landscape. At ACloud POS Solutions, we envision a future where our software becomes an 
                indispensable partner for restaurants, enabling them to not only meet but exceed customer 
                expectations while achieving unprecedented levels of operational excellence." </p>        
                
            </RegDiv>
    )
}

export default Doctrine;