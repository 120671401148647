// SignInPage.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './SignInPage.css'; // Import your CSS file for styling
import {RegDiv} from "../../Components";
import Footer from '../../Components/Footer';
import TittleNavBar from '../../Components/TittleNavBar';
import { Login } from '../../Components/Api';
import GoogleAnalytics from '../../Components/GoogleAnalytics';

const SignIn = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit =async (e) => {
    e.preventDefault();
    // Add your sign-in/authentication logic here
    await Login(formData.username, formData.password);
    navigate("/account");
  };

  return (
    <div>
      <GoogleAnalytics></GoogleAnalytics>
        <Helmet>
        <title>ACloud Pos System</title>
        <meta name="ACloud Pos System Sign in"    content=" Account sign-in to ACloud POS System." />
      </Helmet>
      <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
      <RegDiv padding="4%" width="95vw">
        <TittleNavBar/>
        <RegDiv flexdirection="row" >
          <RegDiv width="30vw"></RegDiv>
          <RegDiv padding="2%" width="40vw">
            <div className="sign-in-page">
            <div className="sign-in-container">
                <h2>Sign In</h2>
                <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                    type="username"
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    />
                </div>

                <button type="submit">Sign In</button>
                </form>
            </div>
            </div>
          </RegDiv>
          <RegDiv width="25vw"></RegDiv>
        </RegDiv>
      </RegDiv>
      <Footer></Footer>
    </div>
    
  );
};

export default SignIn;
