import { Helmet } from 'react-helmet';
import React, { useState } from 'react';

import {RegDiv} from "../../Components";
import '../SignIn/SignInPage.css';
import Footer from '../../Components/Footer';
import TittleNavBar from '../../Components/TittleNavBar';
import GoogleAnalytics from '../../Components/GoogleAnalytics';



function SignUp(){
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // Add your signup/authentication logic here
        //console.log('Form submitted:', formData);
      };
    
    return (
        <div>
          <GoogleAnalytics></GoogleAnalytics>
        <Helmet>
          <title>SignUp of ACloud POS Account</title>
          <meta name="SignUp of ACloud POS Account" content="" />
        </Helmet>
        <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
        <RegDiv padding="2%" width="90vw">
         <TittleNavBar/>
          <RegDiv >  
            
            <div className="sign-in-page">
                <div className="sign-in-container">
                    <h2>Create Your Account</h2>
                    <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="firstName">First Name</label>
                        <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        required
                        />
                    </div>

                    <button type="submit">Sign Up</button>
                    </form>
                </div>
            </div>
          </RegDiv>
        
        </RegDiv>
        <Footer></Footer>
        </div>
    ); 
    
}

export default SignUp;