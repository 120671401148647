import { Helmet } from 'react-helmet';

import {RegDiv} from "../../../Components";
import CNDoctrine from '../../../Components/CN/Doctrine';
 
import CNFooter from '../../../Components/CN/Footer';
import CNTittleNavBar from '../../../Components/CN/Nav';
import GoogleAnalytics from '../../../Components/GoogleAnalytics';

function CNAbout(){

    return (
      <div>
        <GoogleAnalytics></GoogleAnalytics>
      <Helmet>
      <meta charset="UTF-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <title>云峰餐馆收银 - 愿景和使命</title>
      <meta name="description" content="探索云峰收银餐饮解决方案的愿景和使命。我们展望未来，致力于为各种规模的餐厅提供收银解决方案，为其提供灵活性以适应行业趋势，最大程度地提高效率，并提升客户满意度。我们的使命是通过先进的云端收银软件彻底改变餐饮行业，提供直观、可扩展和创新的解决方案。"/>
      <meta name="keywords" content="温哥华云峰餐饮收银解决方案，温哥华餐厅收银，效率，客户满意度，创新，云收银"/>

      </Helmet>
      <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
      <RegDiv padding="4%" width="90vw">
       <CNTittleNavBar/> 

        <RegDiv  flexdirection="row" margin="50px 0 0 0">        
          
          <RegDiv padding="2%">  
            <h2>愿景</h2>       
            <p>展望未来，我们的餐饮收银解决方案赋予各种规模的餐厅卓越的收银能力，使它们能够灵活地适应不断发展的行业趋势，最大程度地提高效率，并提升客户满意度。 </p>
             
          </RegDiv>
          <RegDiv padding="2%">
            <RegDiv>
              <CNDoctrine/>
            </RegDiv>        
             
          </RegDiv>

         
        </RegDiv>
        <RegDiv  flexdirection="row">  
          
        </RegDiv>
      
      </RegDiv>
      <CNFooter/>
      </div>
  ); 
    
}

export default CNAbout;