import React from 'react';
import { Link } from 'react-router-dom';
import '../../../Navigation/navigation.css'; // Import your CSS file for styling

const CNNavbar = () => {
    return (
      <nav style={{ display: "flex", justifyContent: "center", fontSize: "18px" }}>
      <ul style={{ display: "flex", listStyleType: "none", margin: "0", padding: "0" }}>
        <li className="menu-list" style={{ marginRight: "20px", position: "relative" }}>
        <Link to="/home/cn" style={{ fontWeight: "bold" }}>
            首页
          </Link>
          <ul className="sublist">           
            <li className='wide-list'>
              <Link to="/solutions/cn" style={{ fontWeight: "bold", width:"200px" }}>
                解决方案
              </Link>
            </li>
          </ul>
        </li>
        <li style={{ marginRight: "20px" }}>
          <Link to="/features/cn" style={{ fontWeight: "bold" }}>
            功能
          </Link>
        </li>
        <li className="menu-list" style={{ marginRight: "20px", position: "relative" }}>
          <Link to="/prices/cn" style={{ fontWeight: "bold" }}>
            明码标价
          </Link>
          <ul className="sublist">           
            <li className='wide-list'>
              <Link to="/pay/cn" style={{ fontWeight: "bold", width:"200px" }}>
                安全付款
              </Link>
            </li>
          </ul>
        </li>
        <li className="menu-list" style={{ marginRight: "20px", position: "relative" }}>
          <Link to="/about/cn" style={{ fontWeight: "bold" }}>
            关于
          </Link>
          <ul className="sublist">
            <li>
              <Link to="/contact/cn" style={{ fontWeight: "bold" }}>
                联系我们
              </Link>
            </li>
            <li className='wide-list'>
              <Link to="/stories/cn" style={{ fontWeight: "bold", width:"200px" }}>
                客户故事
              </Link>
            </li>
          </ul>
        </li>
        <li style={{ marginRight: "20px" }}>
          <Link to="/signin/cn" style={{ fontWeight: "bold" }}>
            登录
          </Link>
        </li>
        <li style={{ marginRight: "20px" }}>
          <Link to="/faq/cn" style={{ fontWeight: "bold" }}>
            常见问答
          </Link>
        </li>
      </ul>
    </nav>
    
      );
    };


export default CNNavbar;
