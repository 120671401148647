import React, { useState } from 'react';
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'; 

import { AddOrder, AddOrderItems, GetOnlineTableOrders, SetHeaderAuthorization, SetOrderOnline } from "../../Components/Api";
import { RegDiv } from '../../Components';
import TextInputModal from '../../Components/InputPopup';
import WarningModal from '../../Components/Warnning';
import { useParams } from 'react-router-dom';

function ShoppingCart({onClose, onSubmit, order, langIndex}){
  const{token} = useParams();
  const { t} = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phoneNumber, setPhonenumber] = useState('');
  const[isWarningOpen, setWarningOpen] =useState(false);
  const[isSubmited, setSubmited] =useState(false);

  const CloseSubmited=()=>{
    setSubmited(false);
    onSubmit(); 
}


  const CloseWarning=()=>{
      setWarningOpen(false);
  }
  
  const popPhoneInput = ()=>{
    setIsModalOpen(true);
  }
  const GetInput = (value) => {
    setIsModalOpen(false);
    setPhonenumber(value); // Handle the exported input value
  };
 
  const closeModal=()=>{
    setIsModalOpen(false);
  }

  const getDayOfYear = (date) => {
    const start = new Date(date.getFullYear(), 0, 0);
    const diff = date - start;
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(diff / oneDay);
    return dayOfYear;
  };

  const Submit = async(ord)=>{   
    setSubmited(true); 
    
    if(token)
      SetHeaderAuthorization(token);
    if(ord !== null && ord !== undefined)
    {              
      if(ord.Id===0)
      {
        var orders =await GetOnlineTableOrders(ord.TableId);
         
        if(orders && orders.length>0)
        {
          orders[0].Items=ord.Items;
          ord = orders[0];
           
        }
        else
        {
          var curtime = new Date();
          ord.OrderNumber = getDayOfYear(curtime).toString()+curtime.getHours().toString() + 
                          curtime.getMinutes().toString()+curtime.getSeconds().toString();
        
          ord.Ordertime = curtime.toUTCString();  // convert to format C# can understand
          ord.Servetime = curtime.toLocaleDateString('en-US') + " "+ curtime.toLocaleTimeString('en-US');//
          ord.Online=true;
        }        
      }
      if(ord.OrderType!==0)
      {
        
        if((ord.Phone===null || ord.Phone==="") && phoneNumber==="")
        {         
          setWarningOpen(true);
          return;
        }
        ord.Phone=phoneNumber;
      }  
           
      if(ord.Id === 0)
        await AddOrder(ord);
      else
       {
        await AddOrderItems(ord);
        if(ord.Online===false)
          await SetOrderOnline(ord.Id);
       } 
    }   
  }

  const orderSubTotal = (order)=>{
    var subtotal = 0;
    if(order && order.Items)
    {       
        order.Items.forEach(item=>{subtotal = subtotal + item.Quantity*item.ActualPrice});       
    }
    return subtotal;
  }
   
  return (
    <div>
      <RegDiv flexdirection="row" mobileflexdirection="row" width="95vw">
        <RegDiv width="30vw">
            
        </RegDiv>
        <RegDiv width="30vw"  >
            <h4>{t('myorder')}</h4> 
        </RegDiv>
        <RegDiv width="30vw">
        
        </RegDiv>
      
      </RegDiv>
      <RegDiv  >
      {order && order.Items && order.Items.map((item) => (
        <RegDiv flexdirection="row" mobileflexdirection="row" width="90vw" key={item.Item_id}>
            <RegDiv width="70vw">
                <h5>{item.Quantity.toString() + " X " + (langIndex===0?item.Name:(item.Name2?item.Name2:item.Name))} </h5>
            </RegDiv>
            <RegDiv width="18vw">
                <h5> {"$" + item.ActualPrice.toString()} </h5>
            </RegDiv>
        </RegDiv>
      ))}
      </RegDiv>
      <RegDiv>
        <RegDiv>
            ------------------------------------------
        </RegDiv>
        <RegDiv>
            <h4>{t('subtotal')}: $ {orderSubTotal(order)}</h4>
        </RegDiv>
      </RegDiv>
      <RegDiv flexdirection="row"  >
        <RegDiv width="17vw"></RegDiv>
        <RegDiv width="60vw" mobilewidth="90vw" flexdirection="row" mobileflexdirection="row">
          <RegDiv width="100px"  >
              <button
                  key={1}
                  hidden
                  onClick={popPhoneInput}
                  style={{
                  width: '100px',
                  height: '40px', 
                  display: (order && order.OrderType!==0)?'block' : 'none'                
                  }}
                  > {t('Phone')}
              </button>
          </RegDiv>
          <RegDiv width="100px" height="40px"></RegDiv>
          <RegDiv width="100px" >
          <button
                  key={2}                
                  onClick={() => Submit(order)}
                  style={{
                  width: '100px',
                  height: '40px', 
                  display: (isSubmited===false)?'block' : 'none'                   
                  }}
                  > {t('submit')}
              </button>
          </RegDiv>
          <RegDiv width="100px" height="40px"></RegDiv>
          <RegDiv width="100px"  >
              <button
                  key={1}
                  onClick={onClose}
                  style={{
                  width: '100px',
                  height: '40px',                  
                  }}
                  > {t('exit')}
              </button>
          </RegDiv>
        </RegDiv>
        <RegDiv width="17vw"></RegDiv>
      </RegDiv>
      <Modal isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Phone">
         <TextInputModal onOk={GetInput} onCancel={closeModal}/>         
      </Modal>
      <RegDiv flexdirection="row" width="95vw">
        <RegDiv width="30vw"></RegDiv>
        <RegDiv width="30vw">
          <WarningModal isOpen={isWarningOpen} message={t('enterphone')} onClose={CloseWarning}></WarningModal>
        </RegDiv>
        <RegDiv width="30vw"></RegDiv>
      </RegDiv>
      <RegDiv flexdirection="row" width="95vw">
        <RegDiv width="30vw"></RegDiv>
        <RegDiv width="30vw">
          <WarningModal isOpen={isSubmited} message={t('submited')} onClose={CloseSubmited}></WarningModal>
        </RegDiv>
        <RegDiv width="30vw"></RegDiv>
      </RegDiv>
    </div>
  );
};

export default ShoppingCart;