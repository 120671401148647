import React from 'react';
import './Footer.css'; // Import your CSS file for styling if needed
 
import { RegDiv } from '../..';
import CNNavbar from '../Navigation';

function CNFooter() {
  const fileUrl1 = `${process.env.PUBLIC_URL}/UserAgreement.pdf`;
  const fileUrl2 = `${process.env.PUBLIC_URL}/PrivacyPolicy.pdf`;
  return (
    <div>
      <RegDiv backgroundcolor="#585757" flexdirection="row" >
        <RegDiv backgroundcolor="#585757" width="400px"  justifycontent="center" flexdirection="row">
          <RegDiv  backgroundcolor="#585757" width="200px"><h3>社媒链接:</h3></RegDiv>
            <RegDiv  backgroundcolor="#585757" width="100px" padding="2%" flexdirection="row" 
                 mobileflexdirection="row" aligncontent="center">
                <a href="https://www.facebook.com/ACloudPOS/" target='blank'>
                  <img src="../meta_icon.png" alt="Facebook link" height="20px" width="30px"/>
                </a>  
            </RegDiv>  
        </RegDiv >
        <RegDiv backgroundcolor="#585757" width="50vw"  >
          <footer className="footer">
            <div className="footer-content">
              <CNNavbar/>              
              <RegDiv flexdirection="row" backgroundcolor="#585757" margin="10px">               
                <a href={fileUrl1} >
                  软件使用条款 
                </a>  
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href={fileUrl2}  >
                  隐私保护政策 
                </a>                
              <p>&copy; 2024 云峰收银版权所有.</p>
              </RegDiv>
            </div>
          </footer>
        </RegDiv>
      </RegDiv>
    </div>
    
  );
}

export default CNFooter;