import React, { useState, useEffect } from 'react';

import { RegDiv } from '../../Components';
import { GetStoreFunctions } from "../../Components/Api";

function StoreFunctions({onCancel, onConfirm}){
    const [storeFunctions, setStoreFunctions] = useState();
    const [monthFee, setMonthFee] = useState(30);
    const [monthTotal, setMonthTotal] = useState(31.5);
    const [monthTax, setMonthTax] = useState(1.5);
    const [featureCount, setFeatureCount] = useState(0);
    const fetchStorefunctions = async () => {
        try {
            const usr_json = sessionStorage.getItem("userinfo");
            var userinfo = JSON.parse(usr_json);
           
            if(userinfo)
            {
                const data = await GetStoreFunctions(userinfo.StoreId);
                 
                setStoreFunctions(data);
                return data;
            }
        } catch (error) {
          console.error('Error fetching stores:', error);
        }
    };

    const calculateMonthlyFees = (feature)=>{
        let count=0;
        
        if(feature !==null && feature !== undefined)
        {            
            if(feature.BilingualMenus) {
                count++;
            }
            if(feature.ClockService ){
                count++;
            }
            if(feature.DeliveryService ){
                count++;
            }
            if(feature.DualMonitor ){
                count++;
            }
            if(feature.EMenuSelfOrder){
                count++;
            }
            if(feature.InventoryControl){
                count++;
            }
            if(feature.KitchenScreen ){
                count++;
            }
            if(feature.MobileOrder){
                count++;
            }
            if(feature.MultiOrder){
                count++;
            }
            if(feature.PickupService){
                count++;
            }
            if(feature.RandomOrderNumber){
                count++;
            }
            if(feature.SmartWait){
                count++;
            }
            if(feature.TableService){
                count++;
            }
            if(feature.VIP){
                count++;
            }
        }

        
        let fee = 30; // base charge
        fee += count * 5; //every function $5/month
        setFeatureCount(count);
        return fee;
    }
  
    const SetDisplayData = async ()=>{
        let feature =await fetchStorefunctions();
        let fee = calculateMonthlyFees(feature);
        setMonthFee(fee);
        let tax = fee * 0.05;
        setMonthTax(tax);
        let total = fee * (1.05); //add gst
        setMonthTotal(total);
    }
    useEffect(() => {      
        SetDisplayData();
    }, []);

    return(
        <RegDiv flexdirection="row"> 
            
            <RegDiv >  
                <RegDiv>
                    <h2>Your selected features</h2>       
                    
                    <ul >
                    { storeFunctions && storeFunctions.BilingualMenus && (
                    <li>Bilingual Menus </li>
                    )}
                    { storeFunctions && storeFunctions.ClockService && (
                    <li>Clock Service </li>
                    )}
                    { storeFunctions && storeFunctions.DeliveryService && (
                    <li>Delivery Service</li>
                    )}
                    { storeFunctions && storeFunctions.DualMonitor && (
                    <li>Client Screen </li>
                    )}
                    { storeFunctions && storeFunctions.EMenuSelfOrder && (
                    <li>QR Code Scan </li>
                    )}
                    { storeFunctions && storeFunctions.InventoryControl && (
                    <li>Inventory Control</li>
                    )}
                    { storeFunctions && storeFunctions.KitchenScreen && (
                    <li>Kitchen Screen </li>
                    )}
                    { storeFunctions && storeFunctions.MobileOrder && (
                    <li>Mobile Order</li>
                    )}
                    { storeFunctions && storeFunctions.MultiOrder && (
                    <li>Concurrent Order </li>
                    )}
                    { storeFunctions && storeFunctions.PickupService && (
                    <li>Pickup Service </li>
                    )}
                    { storeFunctions && storeFunctions.RandomOrderNumber && (
                    <li>Randomize Order Number</li>
                    )}
                    { storeFunctions && storeFunctions.SmartWait && (
                    <li>Smart Wait</li>
                    )}
                    { storeFunctions && storeFunctions.TableService && (
                    <li>Table Service </li>
                    )}
                    { storeFunctions && storeFunctions.VIP && (
                    <li>V.I.P </li>
                    )}              
                    </ul> 
                   
                    <p>Total features:  {featureCount} <br/>
                    Basic fee is $30/month, additional feature is $5/month per feature.</p>
                    <p>Monthly fee: 30 + 5* {featureCount} = ${monthFee} <br/>
                    Tax(5%): ${monthTax} <br/>
                    Monthly total:  ${monthTotal}</p> 
                </RegDiv>
                
                <RegDiv flexdirection="row">
                    <RegDiv width ="20vw">
                        <button onClick={onConfirm}
                        style={{
                        width: '80px',
                        height: '40px',                  
                        }}
                        >   
                        Confirm</button> 
                    </RegDiv>
                    <RegDiv width="20vw">
                        <button onClick={onCancel}
                        style={{
                            width: '80px',
                            height: '40px',                  
                            }}
                        >
                            Cancel</button>  
                    </RegDiv>
                
                </RegDiv>
                
            </RegDiv>
            
        </RegDiv>
    )
}

export default StoreFunctions;