import { Helmet } from 'react-helmet';

import {RegDiv} from "../../../Components";
import CNFooter from '../../../Components/CN/Footer';
import CNTittleNavBar from '../../../Components/CN/Nav';
import GoogleAnalytics from '../../../Components/GoogleAnalytics';

function CNPrices(){

    return (
      <div>
    <GoogleAnalytics></GoogleAnalytics>
    <Helmet>
        <meta charset="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <title>云峰智能餐饮系统 - 价格及性能优势</title>
        <meta name="description" content="探索云峰智能餐饮系统的定价细节和优势。享受免费收银软件许可证、成本节省以及一系列功能，以优化您的业务运营。"/>
        <meta name="keywords" content="温哥华餐厅POS系统, 智能餐饮系统, 免费收银软件, 定价, 优势, 成本节省, 功能, 定制, 数据安全, 远程访问, 技术支持"/>
    </Helmet>
    <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
    <RegDiv padding="4%" width="90vw">
        <CNTittleNavBar/>
        <RegDiv flexdirection="row">
            <RegDiv margin="50px 0 0 0" justifycontent='center'>
                <RegDiv padding="4%">
                    <h2>推荐及免费使用</h2>
                    <p>是的，您可以获得免费服务，每成功推荐一位用户，您将获得三个月的免费使用权。例如，如果您向四位朋友推荐了我们的智能餐饮系统，而他们都成为了用户，您将获得一整年的免费使用权限。</p>
                </RegDiv>
            </RegDiv>
        </RegDiv>
        <RegDiv flexdirection="row">
            <RegDiv padding="2%" width="40vw">
                <h2>价格信息</h2>
                <p>1. 云峰智能餐饮系统的安装附带免费收银软件许可证。相比较，许多公司会收取几百到几千不等的软件费用及几百元的安装费用，及每月几十上百的技术支持费用。云峰系统帮您省了这些不菲的费用和烦恼。</p>
                <p>2. 在最初的90天内，享受免费云峰餐厅POS系统服务，让您有充足的时间体验及决定云峰是否适合您的餐馆需求。</p>
                <p>3. 在90天过后，继续使用云峰收银服务将支付低廉的月度费用。对于不想按月付费的餐馆老板，请和我们联系另外探讨。</p>
                <p>4. 标准月度费用为30加元，约合22美元，或20.5欧元。</p>
                <p>5. 选择14项功能中的任意功能以满足您的服务要求，每个功能收费5加元/月。</p>
                <p>6. 随时修改您选择的功能，而不会影响月度费用，只要功能的总数保持不变。</p>
                <p>7. 功能总数的更改将导致月度服务费用的调整，并将反映在随后的月度账单中。</p>
                <p>8. 可以随时取消您的服务，费用仅收至当月月底。下个月开始不再收取任何费用。</p>
            </RegDiv>
            <RegDiv padding="2%" width="40vw">
                <h2>云峰智能餐饮系统的好处</h2>
                <p>1. 云峰智能餐饮系统确保显著的成本节省，可为您至少节省数千元。</p>
                <p>2. 云峰系统是一个功能全面的餐饮收银软件，可以定制以满足所有您的业务需求。</p>
                <p>3. 您可以自定义云峰收银软件系统，以符合您的特定需求和偏好。</p>
                <p>4. 即使在您收银电脑出现故障的情况下，也可以保护您的数据，确保数据完整性和连续性。</p>
                <p>5. 通过互联网服务安全便捷地随时随地访问您的数据，让您灵活实时地观察自己生意运行。</p>
                <p>6. 享受免费的软件许可证，安装和90天的免费使用期。</p>
                <p>7. 在免费期后只需支付低廉的月度使用费，甚至可以推荐朋友获得长期的免费使用。如果系统不使用，则不支付任何费用。</p>
                <p>8. 享受免费的技术支持，与其他收银软件供应商形成鲜明对比，他们通常对软件和持续支持费用收取过高的费用。</p>
            </RegDiv>
        </RegDiv>
    </RegDiv>
    <CNFooter/>
</div>

  ); 
    
}

export default CNPrices;