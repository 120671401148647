import React, { useState, useEffect } from 'react';
import './ImageRotator.css'; // Import the CSS file for styling

const CNImageRotator = () => {
  const images = [
    '../emenu.png',
    '../tabletdisplay.png',
    '../page1.png',
    '../page2.png',
    '../page3.png',
    '../page4.png',
    '../page5.png',
    '../page6.png',
    '../page7.png',
    '../page8.png',
    // Add more image URLs as needed
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    // Rotate images every 3 seconds (adjust as needed)
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [currentImageIndex, images.length]);

  return (
    <div className="image-rotator">
      <img src={images[currentImageIndex]} alt={` ${currentImageIndex + 1}`} />
    </div>
  );
};

export default CNImageRotator;
