import React from 'react';
import './Footer.css'; // Import your CSS file for styling if needed
import Navbar from '../../Navigation';
import { RegDiv } from '..';

function Footer() {
  const fileUrl1 = `${process.env.PUBLIC_URL}/UserAgreement.pdf`;
  const fileUrl2 = `${process.env.PUBLIC_URL}/PrivacyPolicy.pdf`;
  return (
    <div>
      <RegDiv backgroundcolor="#585757" flexdirection="row" >
        <RegDiv backgroundcolor="#585757" width="400px"  justifycontent="center" flexdirection="row">
          <RegDiv  backgroundcolor="#585757" width="200px"><h3>Social Media Links:</h3></RegDiv>
            <RegDiv  backgroundcolor="#585757" width="100px" padding="2%" flexdirection="row" 
                 mobileflexdirection="row" aligncontent="center">
                <a href="https://www.facebook.com/ACloudPOS/" target='blank'>
                  <img src="meta_icon.png" alt="Facebook link" height="20px" width="30px"/>
                </a>  
            </RegDiv>  
        </RegDiv >
        <RegDiv backgroundcolor="#585757" width="50vw"  >
          <footer className="footer">
            <div className="footer-content">
              <Navbar></Navbar>
              <RegDiv flexdirection="row" backgroundcolor="#585757" margin="10px">  
              
              <a href={fileUrl1} >
                Terms & Conditions 
              </a>  
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a href={fileUrl2}  >
                Privacy Policy 
              </a>  
            </RegDiv>
              <p>&copy; 2024 ACloud Software Inc. All rights reserved.</p>
            </div>
          </footer>
        </RegDiv>
      </RegDiv>
    </div>
    
  );
}

export default Footer;