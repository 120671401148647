import {RegDiv} from "../../Components";

function Tittle(){

    return (
       
        <RegDiv padding="2%" flexdirection="row" mobileflexdirection="column">
            <img src="ACloud_Logo.ico" alt="ACloud POS icon " height="60px" width="80px"/> &nbsp; &nbsp; <h3 style={{ color: 'blue'}}>ACloud POS  </h3>  
        </RegDiv>
 
  ); 
    
}

export default Tittle;