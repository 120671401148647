import { Helmet } from 'react-helmet';
import {RegDiv} from "../../../Components";
import CNLinks from '../../../Components/CN/Links';
import CNFooter from '../../../Components/CN/Footer';
import CNTittleNavBar from '../../../Components/CN/Nav';import GoogleAnalytics from '../../../Components/GoogleAnalytics';
;

function CNFAQ(){

    return (
      <div>
    <GoogleAnalytics></GoogleAnalytics>
    <Helmet>
        <meta charset="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <title>云峰系统常见问题 - 餐饮管理软件和POS系统</title>
        <meta name="description" content="探索有关云峰智能云POS系统的常见问题。了解如何下载、安装、注册、配置设置等。"/>
        <meta name="keywords" content="智能云POS系统, 餐饮管理软件, 餐饮POS软件安装, 收银软件安装, 注册, 餐馆收银系统设置, 订阅服务"/>
        <meta name="author" content="ACloud Software Inc."></meta>
    </Helmet>
    <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
    <RegDiv padding="4%" width="90vw">
        <CNTittleNavBar/>
        <RegDiv flexdirection="row">        
            <RegDiv padding="5%" width="70%">      
                <h1>智能云POS系统常见问题</h1> 
                <h2>餐饮POS软件安装使用</h2> 
                <p>1. 如何使用云峰智能云POS系统(ACloudPOS)? <br/>
                步骤: <br/>
                1.1 点击右侧下载链接之一，下载安装套件或压缩包，运行安装套件或解压压缩包到指定路径下; <br/>
                1.2 在安装的文件夹中找到XPOS1-Shortcut，将其添加到电脑任务栏。 <br/>
                1.3 运行并设置您的餐馆系统。 <br/>
                1.4 开始使用。  
                </p>
                <p>2. 如何注册? <br/> 
                您需要先在您的Windows计算机上安装云峰智能餐饮管理软件，然后运行并注册。</p> 
                <p>2.1 我接手了一家餐馆，电脑上已经有ACloudPOS软件，还需要注册吗? <br/> 
                是的，您需要重新注册。如果版本有更新提示，您需要下载并更新软件。</p>  
                <p>3. 如何下载云峰智能云POS系统? <br/>
                点击右侧下载链接之一，下载安装套件或压缩包，运行安装套件或解压压缩包到指定路径下。浏览器可能会显示潜在风险警告，请忽略并继续下载。我们保证软件的安全性。</p> 
                <p>4. 云峰智能云POS系统支持的操作系统? <br/>
                云峰智能云POS系统可在Windows 7/8/10/11操作系统的电脑上运行。</p> 
                <p>5. 哪里可以购买触摸屏一体收银电脑? <br/>
                请随时与我们联系。我们提供高质量的餐饮专用一体化触摸屏收银电脑，价格合理。</p> 
                <p>6. 云峰软件安装后如何在电脑启动时自动运行? <br/>
                您可以将ACloudPOS文件夹中的XPOS1-Shortcut复制并放置在Windows启动文件夹中来实现。</p>     
                <p>7. 如何更新我的收银软件? <br/>
                要更新云峰软件，只需点击右侧安装链接之一，下载安装套件或压缩包，运行套件或解压到目标路径，覆盖原有文件。</p>     
                <p>8. 如何从我的计算机中卸载云峰软件? <br/>
                只需删除ACloudPOS安装文件夹即可。</p> 

                <h2>订阅服务</h2>    
                <p>9. 如何订阅? <br/>
                <span>
                    <a href="/signin/cn">从这里登录</a>，点击订阅并按提示操作。
                </span></p>
                <p>10. 订阅后我的免费试用还有效吗? <br/>
                不会失效。您的免费试用将继续有效，只有在免费试用期结束后才会开始收取订阅费用。</p>
                <p>11. 如何取消订阅? <br/>
                <span>
                    <a href="/signin/cn">从这里登录</a>，点击取消订阅。
                </span></p>
                <p>12. 我的餐厅暂时关闭时如何暂停订阅? <br/>
                您可以取消当前订阅，避免月费，并在准备好恢复使用时重新订阅。</p>
            </RegDiv>
            <RegDiv width="20%">
                <RegDiv>
                    <CNLinks/>
                </RegDiv>
            </RegDiv>  
        </RegDiv>
    </RegDiv>
    <CNFooter/>
</div>

  ); 
    
}

export default CNFAQ;