import { Helmet } from 'react-helmet';

import {RegDiv} from "../../../Components";
import CNTittleNavBar from '../../../Components/CN/Nav';
import CNFooter from '../../../Components/CN/Footer';
import CNImageRotator from '../../../Components/CN/ImageRotator';
import CNLinks from '../../../Components/CN/Links';
import GoogleAnalytics from '../../../Components/GoogleAnalytics';

function CNHome(){

    return (
      <div>
      <GoogleAnalytics></GoogleAnalytics>
      <Helmet>
          <meta charset="UTF-8"/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <title>云峰餐厅POS系统：最佳免费餐厅POS软件</title>
          <meta name="description" content="欢迎使用云峰云端餐厅POS系统 - 您的下一代餐厅POS系统解决方案！探索云峰POS系统为餐厅带来的革命性功能、成本节约和卓越价值。通过我们先进的云端POS系统，带来效率、准确性和创新。"/>
          <meta name="keywords" content="餐厅POS系统, 最好餐厅POS系统, 免费餐厅POS软件, 云峰餐馆收银扫码系统, 温哥华餐馆触摸屏电脑点餐系统, 加拿大餐厅免费云端收银系统, 革新业务, 节省成本, 全面功能, 定制化, 数据安全, 免费收银系统, 灵活定价, 技术支持"/>
      </Helmet>
      <RegDiv height="5vh" backgroundcolor="skyblue"></RegDiv>
      <RegDiv width="95vw" padding="4%">
          <RegDiv flexdirection="row">
              <CNTittleNavBar/>
              <a href="/"  >English</a>
          </RegDiv>
  
          <RegDiv flexdirection="row" margin="50px 0 0 0">        
              <RegDiv width="70%" padding="4vw"> 
                  <RegDiv> 
                      <RegDiv>
                          <h1>云峰餐厅POS系统让您的餐馆焕然一新</h1> 
                          <h3>欢迎访问云峰餐厅POS系统 - 全新一代餐馆收银扫码解决方案!</h3>
                      </RegDiv>
                      <RegDiv flexdirection="row">
                          <RegDiv width="60%">
                              <p>云峰餐厅POS系统不仅是一款软件；它是一个基于云系统计算的全面的餐馆触摸屏电脑点餐系统解决方案，旨在重新定义您进行交易和管理业务的方式。我们先进的 POS 系统将先进技术与用户友好的设计无缝集成在一起，为您提供一个强大的工具，可以适应您业务的独特需求。您可以根据自己的餐馆需求来选择自己需要的功能和设置，不论您的生意是快餐，小餐馆，中型餐馆还是大型餐馆。正是因为独特的功能选择，才能让您以最低的价格满足您餐馆的需求，提升您的服务水平和效率。如果您是准备开业的老板，经过装修等工程，手头的现金被花了很多，不想再花更多的钱，那么云峰系统正好满足您的需求，帮您省下至少数千元。</p>
                          </RegDiv>
                          <RegDiv width="30%" padding="5%">                
                              <CNImageRotator/>
                          </RegDiv>       
                      </RegDiv>
                      <RegDiv height="10vh"></RegDiv>
                      <RegDiv>  
                          <h1>为什么选择云峰餐厅POS系统?</h1> 
                          <h2>节约成本与价值提升</h2>       
                          <p>云峰系统帮您实现显著的成本节约，为您节省数千元以上。通过成功推荐给其他餐厅业主，我们的系统可以免费使用，或者付极低的费用，同时提供效率、准确性和整体业务绩效的提升。</p>
                          <h2>多样化的功能</h2>       
                          <p>丰富多样的功能、设计和详尽的报告，满足您所有的业务需求。从库存管理到销售分析，云峰餐厅POS系统确保您一切尽在掌握。</p>
                          <h2>量身定制以满足您的需求</h2>       
                          <p>根据您的业务需求定制选择云峰系统功能，使其与您的业务需求完美契合。调整系统以匹配您独特的工作流程和偏好，确保个性化和高效的体验。</p>
                          <h2>数据安全及便捷访问</h2>
                          <p>即使您的电脑发生意外故障，您的数据也是安全的，完全不用担心丢失。因为云峰系统可以很好地保护您的数据和信息，您可以随时随地通过互联网服务访问它。</p>
                          <h2>免费安装和使用云峰餐厅POS系统</h2>
                          <p>使用我们慷慨的 90 天免费使用期、无忧的安装以及免费软件许可证，开始您的云峰POS之旅吧。体验我们系统的强大功能，无需任何前期费用。</p>
                          <h2>灵活、低廉的费用</h2>
                          <p>我们对价格亲民的承诺在90天免费期后持续，只有在您积极使用系统时才需要支付低廉的月度使用费，从而提供了灵活性和成本效益。</p>
                          <h2>长期免费使用</h2>
                          <p>如果您向朋友推荐云峰系统，每成功推荐一位，就能享受3个月的月费免除，如果成功推荐四位，就能享受一年的免费使用，以此类推。</p>
                          <h2>帮助信息</h2>
                          <p>为了使用者的方便，云峰系统提供了帮助信息，点击页面上的帮助按钮可以快速获得有关导航和操作的指导。</p>
                          <h2>良好的技术支持</h2>
                          <p>无论何时您需要帮助，都可以依靠我们专业的团队提供免费的技术支持。与其他POS软件供应商不同，我们坚信提供一流的支持服务，并且不会收取额外费用，帮您每月节省上百元的费用</p>
                          <p>准备好让云峰系统帮您提升您的业务了吗？探索无缝交易和优化运营的未来。加入无数已经选择云峰系统的餐馆，享受效率、成本效益和创新带来的好处。立即开始吧！</p>
                      </RegDiv>
                  </RegDiv>
              </RegDiv>
              <RegDiv width="20%">
                  <RegDiv>
                      <CNLinks/>
                  </RegDiv>
              </RegDiv>  
          </RegDiv>
      </RegDiv>
      <CNFooter></CNFooter>
  </div>
  
  ); 
    
}

export default CNHome;